import React from "react";
import Typography from "@material-ui/core/Typography";
import {Box, Button, Card, CardContent, GridList, GridListTile, IconButton, Paper, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import SendIcon from '@material-ui/icons/Send';

import {QueryResultType, QueryUIType, rtttApiClient} from "../logic/RtttApiClient";

const useStyles = makeStyles((theme) => ({
  inputs: {
    padding: theme.spacing(3),
  },
  buttons: {
    marginRight: theme.spacing(1),
  },
  questionHolder: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  question: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: '12px',
    background: 'lightblue',
    boxShadow: '1px 1px 1px black',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    height: '300px',
  },
  gridListItem: {
    margin: theme.spacing(1),
  },
}));

export function QueryLayout() {
  const classes = useStyles();
  const [url, setUrl] = React.useState<string>('');
  const [query, setQuery] = React.useState<string>('');

  const [scrapes, setScrapes] = React.useState<object>(undefined);
  const [queryResults, setQueryResults] = React.useState<object>(undefined);
  const [queryUI, setQueryUI] = React.useState<QueryUIType[]>([]);

  React.useEffect(() => {
    const scrapesCallback = (message: object) => setScrapes(message);
    rtttApiClient.subQueryScrapeResults(scrapesCallback);
    return () => rtttApiClient.unsubQueryScrapeResults(scrapesCallback);
  }, []);

  React.useEffect(() => {
    const queryResultsCallback = (message: object) => setQueryResults(message);
    rtttApiClient.subQuerySearch(queryResultsCallback);
    return () => rtttApiClient.unsubQuerySearch(queryResultsCallback);
  }, []);

  React.useEffect(() => {
    const queryUICallback = (message: QueryUIType[]) => setQueryUI(message.slice().reverse());
    rtttApiClient.subQueryUIStream(queryUICallback);
    return () => rtttApiClient.unsubQueryUIStream(queryUICallback);
  }, []);

  return <main>
    <Box paddingTop={2}/>
    <Typography variant="h5" gutterBottom>
      Load documents
    </Typography>
    <Paper className={classes.inputs}>
      <Typography variant="h6">From web pages</Typography>
      <TextField label="Add document by URL" placeholder="https://.." onChange={e => setUrl(e.target.value)} fullWidth={true}/>
      <IconButton color="primary" className={classes.buttons} disabled={!url.startsWith('http') || url.length < 8} onClick={() => {
        setScrapes(undefined);
        rtttApiClient.sendQueryScrapeAdd(url)
      }}>
        Add&nbsp;<SendIcon/>
      </IconButton>
      <Button color="primary" variant="outlined" className={classes.buttons} onClick={() => {
        setScrapes(undefined);
        rtttApiClient.sendQueryScrapeClear()
      }}>
        Clear Documents
      </Button>
      <Button color="primary" variant="outlined" className={classes.buttons} onClick={() => {
        setScrapes(undefined);
        rtttApiClient.sendQueryScrapeAdd('https://en.wikipedia.org/wiki/Coronavirus_disease_2019');
        // rtttApiClient.sendScrape('https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/how-covid-spreads.html');

        const sites = ['https://www.cdc.gov/coronavirus/2019-ncov/community/colleges-universities/considerations.html',
          'https://www.cdc.gov/coronavirus/2019-ncov/community/colleges-universities/ihe-testing.html',
          'https://www.cdc.gov/coronavirus/2019-ncov/community/guidance-ihe-response.html',
          'https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/clean-disinfect-hygiene.html',
          'https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/cloth-face-cover.html',
          // 'https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/decision-tool.html',
          // 'https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/k-12-staff.html',
          // 'https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/k-12-testing.html',
          // 'https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/parent-checklist.html',
          // 'https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/prepare-safe-return.html',
          'https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/reopening-schools.html',
          'https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/schools.html',
          'https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/symptom-screening.html',
        ]
        for (const site of sites)
          rtttApiClient.sendQueryScrapeAdd(site);

      }}>
        Load Defaults
      </Button>
      <br/>
      {scrapes && <>
        <Typography>Server response:</Typography>
        <pre>{JSON.stringify(scrapes, null, 2)}</pre>
      </>}
    </Paper>

    <Box padding={4}/>
    <Typography variant="h5" gutterBottom>
      Query results
    </Typography>
    <Box>
      <TextField label="Question..." placeholder="How old was he?" onChange={e => setQuery(e.target.value)}/>
      <IconButton color="primary" aria-label="directions" onClick={() => rtttApiClient.sendQuerySearch(query)} disabled={query.length < 5}>
        <SendIcon/>
      </IconButton>

      <Box>
        {queryUI.map(item => {
          if (item.sent) {
            return <Box textAlign="right" key={`ui-${item.idx}`} className={classes.questionHolder}>
              <Typography component="span" color="textPrimary" gutterBottom className={classes.question} align="right">
                {item.query}
              </Typography>
            </Box>;
          }
          else {
            return <GridList className={classes.gridList} cols={2.2} key={`ui-${item.idx}`}>
              {item.results.map((result: QueryResultType, rIdx: number) => (
                <GridListTile key={`ui-${item.idx}-${rIdx}`} style={{height: '270px'}}>
                  <Card elevation={2} className={classes.gridListItem}>
                    <CardContent>
                      <Typography color="primary">{result.href}</Typography>
                      {/*result.line_prev && <Typography>{result.line_prev}</Typography>*/}
                      <Typography>{result.line}</Typography>
                      {/*result.line_next && <Typography>{result.line_next}</Typography>*/}
                      <Typography color="textSecondary">{`Score: ${result.score.toFixed(3)}`}</Typography>
                    </CardContent>
                  </Card>
                </GridListTile>
              ))}
            </GridList>;
          }
        })}
      </Box>

      {queryResults && <TextField label="Server response" multiline rows={12} fullWidth={true} variant="outlined" contentEditable={false}
                                  style={{marginTop: '1em'}} value={JSON.stringify(queryResults, null, 2)} defaultValue="..."/>}
    </Box>

  </main>;
}
