/*
Connects to the API endpoint via socket.io.
 */
import io from "socket.io-client";
import {err, log, ObservableStream, ObservableValue} from "./utils";
import {SourceDataType, TransformType} from "./ConversationTransform";

// configuration
const FORCE_LOCALHOST_RTTT_BACKEND = false;
const FORCE_WEBSOCKET = false;
const BACKEND_SERVER = FORCE_LOCALHOST_RTTT_BACKEND ?
  'http://localhost:1998' :           // locally
  'https://www.racetothetrillion.com' // production server for socket.io (note that env.PUBLIC_URL will reflect re-bases)
const SOCKET_BACKEND_PATH_BASE = FORCE_LOCALHOST_RTTT_BACKEND ?
  '' :                                // locally
  (process.env.PUBLIC_URL || '');     // when in production, and the api is behind a proxy that relocates it
const API_PATH_BASE = SOCKET_BACKEND_PATH_BASE + '/api';
const API_PATH_SIO_RTTT = API_PATH_BASE + '/socket/rttt';


// types: Finance

// NOTE: keep this in sync with the backend (ApiServer.js)
export interface FinConfigType {
  [symbol: string]: any
}

// NOTE: keep this in sync with the backend (ApiServer.js)
export interface FinTradeType {
  // wire fields
  p: number,
  s: string,
  t: number,
  v: number,
  // added fields
  seq: number,  // added, sequence number, monotonic since the start of the client - TODO: replace with server-side
  dir: number,  // 1: up, 0: same, -1: down
}

// UI type only
export interface FinLastPricesType {
  [symbol: string]: number
}

export interface FinIpoCalendarType {
  calendar: FinIpoCalendarEntryType[],
  from: string,
  to: string,
}

export interface FinIpoCalendarEntryType {
  date: string,                   // ISO date
  exchange: string | null,        // NYSE
  name: string,                   // Enrico's Corp
  numberOfShares: number | null,  // priced|expected: number, or null
  price: string | null,           // priced: string value, expected: string range|value, or null
  status: string,                 // filed -> withdrawn -> expected -> priced
  symbol: string,                 // [* can be empty, can be duplicate] symbol
  totalSharesValue: number,       // total valuation
  isSPAC?: boolean,               // undefined if not assessed (automatically or manually yet), true if set, false if cleared
}


// types: NLP Query

export interface QueryUIType {
  idx: number,
  sent: boolean,  // true: outgoing message, false: incoming results
  query?: string, // sent: true
  results?: QueryResultType[],  // sent: false
  // timestamp: number,
}

export interface QueryResultType {
  href: string,
  line: string,
  line_prev: string,
  line_next: string,
  number: number,
  score: number,
}


// types: Ahead

export interface AheadResultType {
  // for stock quotes
  symbol?: string,
  entity?: string,
  quote?: object,
  // for errors
  error?: string,
}


// types: Conversation Transform

export interface ConversationTransformInlineType {
  source: SourceDataType,
  transforms: TransformType[],
}

export interface ConversationTransformResultType {
  output: number[][],
  input: {
    source: SourceDataType,
    transforms: TransformType[],
  },
  time: number,
}


// types: Timer

export enum TimerScene {
  Logo = 0,
  Timer = 1,
  Agenda = 2,
  Blank = 3,
}

export enum TimerZeroBehavior {
  Down_Plus = 0,
  Down_Minus = 1,
  Zero = 2,
  QNA = 3,
  Emoji = 4,
}

export enum TimerTheme {
  Dark = 0,
  Light = 1,
}

export enum TimerBar {
  Off = 0,
  Progress_V_Dec = 1,
  Progress_V_Inc = 2,
  Progress_H_Dec = 3,
  /*Progress_H_Inc = 4, commented to avoid an unused constant, as the code doesn't explicitly refer this */
}

// <- incoming (and Partial<> outgoing)
export interface TimerStatusType {
  // modified by config updates
  scene: number,
  theme: number,
  // logo looks (instant)
  logoText: string,
  // timer looks (instant)
  zeroBehavior: number,
  zeroEmojiIndex: number,
  progressBar: number,
  // current timer settings (not altered at runtime)
  t_duration: number,
  t_warning: number,
  t_alert: number,
  timerText: string,
  // modified by commands (start/stop/pause/resume)
  started: boolean,
  paused: boolean,
}

export interface TimerAgendaItemType {
  // definition (updated by the client)
  label: string,
  duration: number, // in seconds
  started: number, // number of times it's been started
  // runtime (updated by the server)
  elapsed: null | number,
}

export interface TimerAgendaType {
  items: TimerAgendaItemType[],
  activeIdx: number,
}

export interface TimerTickType {
  t: number,
}

export const EXPIRED_EMOJIS = ['💲', '💵', '💸', '💰', '💹', '📈', '🤑' /*'💪',*/]


class RtttApiClient {
  private readonly socketToServer: SocketIOClient.Socket;
  private connected: boolean = false;
  private readonly rtttConnected: ObservableValue<boolean>;

  private readonly finConfigValue: ObservableValue<FinConfigType>;
  private finStreamStarted: boolean;
  private readonly finTradeStream: ObservableStream<FinTradeType>;
  private readonly finLastPriceValues: ObservableValue<FinLastPricesType>;
  private readonly finIpoCalendarValue: ObservableValue<FinIpoCalendarType>;
  private readonly symbolLastPrices: FinLastPricesType = {};

  private readonly queryScrapeResults: ObservableValue<object>;
  private readonly querySearchResults: ObservableValue<object>;
  private readonly queryUiStream: ObservableStream<QueryUIType>;

  private readonly aheadResult: ObservableValue<AheadResultType>;

  private readonly conversationTransformResult: ObservableValue<ConversationTransformResultType>;
  private readonly conversationTranscribeResult: ObservableValue<object>;

  private readonly timerStatus: ObservableValue<TimerStatusType>;
  private readonly timerAgenda: ObservableValue<TimerAgendaType>;
  private readonly timerTick: ObservableValue<TimerTickType>;


  constructor() {
    const socketIoOpts = {path: API_PATH_SIO_RTTT};
    if (FORCE_WEBSOCKET)
      Object.assign(socketIoOpts, {transports: ["websocket"]});
    this.socketToServer = io(BACKEND_SERVER, socketIoOpts);
    this.rtttConnected = new ObservableValue<boolean>();
    this.socketToServer.on('connect', () => {
      this.connected = true;
      this.rtttConnected.setValue(true);
    });
    this.socketToServer.on('disconnect', () => {
      log('Disconnected from the Rttt server');
      this.connected = false;
      this.rtttConnected.setValue(false);
    });

    // <- Finance

    this.finConfigValue = new ObservableValue<FinConfigType>();
    this.socketToServer.on('@fin:config', (config: FinConfigType) => this.finConfigValue.setValue(config));

    this.finStreamStarted = false;

    this.finTradeStream = new ObservableStream<FinTradeType>(10);
    this.finLastPriceValues = new ObservableValue<FinLastPricesType>();
    let trades_seq = 0;
    this.socketToServer.on('@fin:trade', (trade: FinTradeType) => {
      // add sequence number
      trade.seq = ++trades_seq;
      // add directionality
      const symbol = trade.s;
      const last = trade.p;
      const prev = this.symbolLastPrices[symbol] || last;
      this.symbolLastPrices[symbol] = last;
      trade.dir = last > prev ? 1 : (last < prev ? -1 : 0);

      // add to the Trades and Prices streams
      this.finTradeStream.appendToStream(trade)
      this.finLastPriceValues.setValue(this.symbolLastPrices);
    });

    this.finIpoCalendarValue = new ObservableValue<FinIpoCalendarType>();
    this.socketToServer.on('@fin:ipo', (cal: FinIpoCalendarType) => this.finIpoCalendarValue.setValue(cal));

    // <- NLP Query

    this.queryScrapeResults = new ObservableValue<object>();
    this.socketToServer.on('@query:scrape-results', (message: object) => {
      this.queryScrapeResults.setValue(message);
    });
    this.querySearchResults = new ObservableValue<object>();
    this.queryUiStream = new ObservableStream<QueryUIType>();
    this.socketToServer.on('@query:search-results', (message: object) => {
      this.querySearchResults.setValue(message);
      if ('results' in message) {
        this.queryUiStream.appendToStream({
          idx: this.queryUiStream.streamLength(),
          sent: false,
          results: message['results'],
        });
      }
    });

    // <- Ahead

    this.aheadResult = new ObservableValue<AheadResultType>();
    this.socketToServer.on('@ahead:result', (message: object) => {
      this.aheadResult.setValue(message);
    });

    // <- Conversation Transform

    this.conversationTransformResult = new ObservableValue<ConversationTransformResultType>();
    this.socketToServer.on('@conversation:transform-inline', (result: ConversationTransformResultType) => {
      this.conversationTransformResult.setValue(result);
    });
    this.conversationTranscribeResult = new ObservableValue<object>();
    this.socketToServer.on('@conversation:transcribe-file', r => this.conversationTranscribeResult.setValue(r));

    // <- Timer

    this.timerStatus = new ObservableValue<TimerStatusType>();
    this.socketToServer.on('@timer:status', (status: TimerStatusType) =>
      this.timerStatus.setValue(status));

    this.timerAgenda = new ObservableValue<TimerAgendaType>();
    this.socketToServer.on('@timer:agenda', (agenda: TimerAgendaType) =>
      this.timerAgenda.setValue(agenda));

    this.timerTick = new ObservableValue<TimerTickType>();
    this.socketToServer.on('@timer:tick', (tick: TimerTickType) =>
      this.timerTick.setValue(tick));
  }


  // subscribable basics

  subToConnected = (callback: (connected: boolean) => void) =>
    this.rtttConnected.addSubscriber(callback);

  unsubFromConnected = (callback: (connected: boolean) => void) =>
    this.rtttConnected.removeSubscriber(callback);


  // -> Finance

  sendFinAdd(symbol: string) {
    if (!this.connected) return err(`cannot add: disconnected from server`);
    this.socketToServer.emit('@fin/trades/add', symbol);
  }

  sendFinRemove(symbol: string) {
    if (!this.connected) return err(`cannot remove: disconnected from server`);
    this.socketToServer.emit('@fin/trades/del', symbol);
  }

  sendFinStreamStartIfStopped() {
    if (!this.connected) return err(`cannot start stream: disconnected from server`);
    if (this.finStreamStarted) return;
    this.socketToServer.emit('@fin/trades/start');
    this.finStreamStarted = true;
  }

  sendFinStreamStopIfUnused() {
    if (!this.connected) return err(`cannot stop stream: disconnected from server`);
    if (!this.finStreamStarted || this.finTradeStream.hasSubscribers() || this.finLastPriceValues.hasSubscribers())
      return;
    this.socketToServer.emit('@fin/trades/start');
    this.finStreamStarted = false;
  }

  sendFinGetIpoCalendar() {
    if (!this.connected) return err(`cannot ipo calendar: disconnected from server`);
    this.socketToServer.emit('@fin/ipo/get');
  }

  subFinConfig = (callback: (config: FinConfigType) => void) =>
    this.finConfigValue.addSubscriber(callback);

  unsubFinConfig = (callback: (config: FinConfigType) => void) =>
    this.finConfigValue.removeSubscriber(callback);

  subFinTrades = (callback: (stream: FinTradeType[], total: number) => any) => {
    this.finTradeStream.addSubscriber(callback);
    this.sendFinStreamStartIfStopped();
  };

  unsubFinTrades = (callback: (stream: FinTradeType[], total: number) => any) => {
    this.finTradeStream.removeSubscriber(callback);
    this.sendFinStreamStopIfUnused();
  };

  subFinPrices = (callback: (prices: FinLastPricesType) => void) => {
    this.finLastPriceValues.addSubscriber(callback);
    this.sendFinStreamStartIfStopped();
  };

  unsubFinPrices = (callback: (prices: FinLastPricesType) => void) => {
    this.finLastPriceValues.removeSubscriber(callback);
    this.sendFinStreamStopIfUnused();
  };

  subFinIpoCalendar = (callback: (value: FinIpoCalendarType) => void) => {
    this.finIpoCalendarValue.addSubscriber(callback);
    this.sendFinGetIpoCalendar();
  };

  unsubFinIpoCalendar = (callback: (value: FinIpoCalendarType) => void) =>
    this.finIpoCalendarValue.removeSubscriber(callback);


  // -> NLP Query

  sendQueryScrapeAdd(url: string) {
    if (!this.connected) return err(`cannot send scrape: disconnected from server (${url})`);
    this.socketToServer.emit('@query/scrape/add', url);
  }

  sendQueryScrapeClear() {
    if (!this.connected) return err(`cannot send scrape clear: disconnected from server`);
    this.socketToServer.emit('@query/scrape/clear');
  }

  sendQuerySearch(text: string) {
    if (!this.connected) return err(`cannot send query: disconnected from server (${text})`);
    this.socketToServer.emit('@query/search', text);
    // local echo
    this.queryUiStream.appendToStream({
      idx: this.queryUiStream.streamLength(),
      sent: true,
      query: text,
    });
  }

  subQueryScrapeResults = (callback: (message: object) => void) =>
    this.queryScrapeResults.addSubscriber(callback);

  unsubQueryScrapeResults = (callback: (message: object) => void) =>
    this.queryScrapeResults.removeSubscriber(callback);

  subQuerySearch = (callback: (message: object) => void) =>
    this.querySearchResults.addSubscriber(callback);

  unsubQuerySearch = (callback: (message: object) => void) =>
    this.querySearchResults.removeSubscriber(callback);

  subQueryUIStream = (callback: (stream: QueryUIType[]) => void) =>
    this.queryUiStream.addSubscriber(callback);

  unsubQueryUIStream = (callback: (stream: QueryUIType[]) => void) =>
    this.queryUiStream.removeSubscriber(callback);


  // -> Ahead

  sendAheadGetPrice(symbol?: string, entity?: string) {
    if (!this.connected) return err(`cannot send query: disconnected from server (${symbol})`);
    this.socketToServer.emit('@ahead/price', {
      context: 'finance',
      symbol: symbol,
      entity: entity,
    });
  }

  subAheadResult = (callback: (message: object) => void) =>
    this.aheadResult.addSubscriber(callback);

  unsubAheadResult = (callback: (message: object) => void) =>
    this.aheadResult.removeSubscriber(callback);


  // -> Conversation Transform

  sendConversationTransformInline(request: ConversationTransformInlineType) {
    if (!this.connected) return err(`cannot send transform-inline: disconnected from server`);
    this.socketToServer.emit('@conversation/transform/inline', request);
  }

  sendConversationTranscriptionForAudioFile(name: string, size: number, type: string, data: ArrayBuffer, md5: string, extraJson?: object) {
    if (!this.connected) return err(`cannot send audio for transcription: disconnected from server`);
    this.socketToServer.emit('@conversation/transcribe/file', {uid: null, name, size, type, data, md5, extraJson});
  }

  subConversationTransformResult = (callback: (message: ConversationTransformResultType) => void) =>
    this.conversationTransformResult.addSubscriber(callback);

  unsubConversationTransformResult = (callback: (message: ConversationTransformResultType) => void) =>
    this.conversationTransformResult.removeSubscriber(callback);

  subConversationTranscribeResult = (callback: (message: object) => void) =>
    this.conversationTranscribeResult.addSubscriber(callback);

  unsubConversationTranscribeResult = (callback: (message: object) => void) =>
    this.conversationTranscribeResult.removeSubscriber(callback);


  // -> Timer

  sendTimerConfigUpdate(update: Partial<TimerStatusType>) {
    if (!this.connected) return err(`cannot send timer: disconnected from server`);
    this.socketToServer.emit('@timer/update/config', update);
  }

  sendTimerAgendaUpdate(idx: number, update: Partial<TimerAgendaItemType>) {
    if (!this.connected) return err(`cannot send timer: disconnected from server`);
    this.socketToServer.emit('@timer/update/agenda-item', idx, update);
  }

  sendTimerCommand(cmdName: string) {
    if (!this.connected) return err(`cannot send timer: disconnected from server`);
    this.socketToServer.emit('@timer/command', cmdName);
  }

  sendTimerAgendaCommand(cmdName: string, idx?: number) {
    if (!this.connected) return err(`cannot send timer: disconnected from server`);
    this.socketToServer.emit('@timer/agenda-command', cmdName, idx);
  }

  subTimerStatus = (callback: (state: TimerStatusType) => void) =>
    this.timerStatus.addSubscriber(callback);

  unsubTimerStatus = (callback: (state: TimerStatusType) => void) =>
    this.timerStatus.removeSubscriber(callback);

  subTimerAgenda = (callback: (state: TimerAgendaType) => void) =>
    this.timerAgenda.addSubscriber(callback);

  unsubTimerAgenda = (callback: (state: TimerAgendaType) => void) =>
    this.timerAgenda.removeSubscriber(callback);

  subTimerTick = (callback: (tick: TimerTickType) => void) =>
    this.timerTick.addSubscriber(callback);

  unsubTimerTick = (callback: (tick: TimerTickType) => void) =>
    this.timerTick.removeSubscriber(callback);
}

export const rtttApiClient = new RtttApiClient();