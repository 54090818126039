/**
 * Enrico Ros 2020 - JS Observables and other utility functions
 */

export const log = console.log;
export const err = console.error;

/**
 * Base class for subscribable client types. Note that at the beginning the type is undefined, and before
 * being set it will not be notified.
 */
class Subscribable<T> {
  private readonly observers: ((data: T, other?: any) => any)[] = [];
  protected data: T | undefined = undefined;

  /*public forEffect(observerFn: (data: T, other?: any) => any): () => void {
    this.addSubscriber(observerFn);
    return () => this.removeSubscriber(observerFn);
  }*/

  public addSubscriber(observerFn: (data: T, other?: any) => void) {
    // add the observer is missing
    if (this.observers.indexOf(observerFn) !== -1) return err(`subscribe: observer already subscribed`);
    this.observers.push(observerFn);

    // activate this immediately with the current data (if set)
    if (this.data !== undefined)
      observerFn(this.data);
  }

  public removeSubscriber(observerFn: (data: T, other?: any) => void) {
    const index = this.observers.indexOf(observerFn);
    if (index === -1) return err(`unsubscribe: observer not subscribed`);
    this.observers.splice(index, 1);
  }

  public hasSubscribers = () => this.observers.length > 0;

  protected notifySubscribers(data: T, other?: any) {
    for (const observerFn of this.observers)
      observerFn(data, other);
  }
}

// export class RtttObservableVolatile<T> extends Subscribable<T> {
//   broadcast(data: T) {
//     this.notifySubscribers(data);
//   }
// }

export class ObservableValue<T> extends Subscribable<T> {
  setValue(data: T) {
    this.data = data;
    this.notifySubscribers(this.data);
  }
}

const DEFAULT_STREAM_CAPACITY = 1000;

export class ObservableStream<T> extends Subscribable<T[]> {
  private readonly max_capacity: number;
  private pushCounter: number;

  constructor(capacity?: number) {
    super();
    this.max_capacity = capacity || DEFAULT_STREAM_CAPACITY;
    this.pushCounter = 0;
  }

  appendToStream(data: T) {
    // create the array upon first access (undefined by default)
    if (this.data === undefined) this.data = [];

    // add the value
    this.data.push(data);

    // keep the stream below capacity, if a limit is set
    if (this.max_capacity && this.data.length > this.max_capacity)
      this.data.splice(0, this.data.length - this.max_capacity);

    // add to the push counter
    this.pushCounter += Array.isArray(data) ? data.length : 1;

    this.notifySubscribers(this.data, this.pushCounter);
  }

  streamLength = () => this.data ? this.data.length : 0;
}
