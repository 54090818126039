import React from 'react';
import {AppBar, Box, Button, Card, Container, Grid, Hidden, IconButton, Link, Toolbar, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import {Link as RouterLink, Route, Switch, useLocation} from "react-router-dom";
import Lottie from 'lottie-react-web';

import animationMarket from './data/animation_market.json';
import animationHorse from './data/animation_horse.json';

import {AheadLayout} from "./layouts/AheadLayout";
import {ConversationTransformLayout} from "./layouts/ConversationTransformLayout";
import {FinanceLayout} from "./layouts/FinanceLayout";
import {QueryLayout} from "./layouts/QueryLayout";
import {TimerEditorLayout} from "./layouts/TimerEditorLayout";

const appStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'aliceblue',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    boxShadow: '0 4px 30px #e4e4e4',
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
}));

/**
 * Used mainly to adapt the title to the current App
 */
function TopBar() {
  return <AppBar position="relative" color="default">
    <Toolbar>
      {/* Left Icon */}
      <Box mr={2}>
        <Switch>
          <Route path="/" exact={true}>
            <IconButton disabled>
              {/*<Avatar variant="rounded" src="android-chrome-192x192.png" style={{width: '32px', height: '32px'}}/>*/}
              <MonetizationOnIcon color="primary"/>
            </IconButton>
          </Route>
          <Route path="/julia" exact={true}/>
          <Route>
            <IconButton component={RouterLink} to="/">
              <ArrowBackIcon color="primary"/>
            </IconButton>
          </Route>
        </Switch>
      </Box>

      {/* Title Text */}
      <Box flexGrow={1}>
        <Typography variant="h5" noWrap style={{fontWeight: 200}}>
          <Switch>
            <Route path="/ahead">
              AHEAD - perception testing
            </Route>
            <Route path="/julia">
              Ventures Timer - Control Panel
            </Route>
            <Route path="/query">
              NLP Document Search
            </Route>
            <Route path="/transform">
              <span role="img" aria-label="Butterfly">🦋</span> Conversation Transform <span role="img" aria-label="Butterfly">🦋</span>
            </Route>
            <Route>
              race-to-the-trillion
              <Hidden xsDown>
                <Box display="inline" style={{opacity: 0.5, fontSize: '0.8em'}}>&nbsp; - fintech & nlp experiments</Box>
              </Hidden>
            </Route>
          </Switch>
        </Typography>
      </Box>

      {/* Right Options */}
      <Box display="flex" flexDirection="row" alignItems="center" alignContent="middle" flexGrow={0} mr={8}>
        {/* Status indicators
        ...
        <IconButton disabled style={{marginLeft: '1rem'}}>
          <SettingsIcon fontSize="large"/>
        </IconButton> */}
      </Box>
    </Toolbar>
  </AppBar>;
}

function Footer() {
  const isRoot = useLocation().pathname === '/';
  return <footer>
    <Box textAlign="center" mt={3} padding={2}>
      {!isRoot && <Box display="inline" mr={6}>
        <Button component={RouterLink} to="/">
          Home
        </Button>
      </Box>}
      <Typography display="inline" variant="body2" color="textSecondary" align="center">
        Copyright © <Link color="primary" href="https://www.enricoros.com">Enrico Ros</Link>, {new Date().getFullYear()}
      </Typography>
    </Box>
  </footer>;
}

const WelcomeMessage = ({classes}) =>
  <Typography variant="body1" component="h4" className={classes.title} gutterBottom>
    {/*Private website.*/}
    <Box marginLeft={1} marginRight={1}>
      <Lottie width="64px" height="64px" options={{animationData: animationHorse}}/>
    </Box>
    Only some experiments here. Shoo shoo.
  </Typography>;

const ExperimentCard = ({label, to}) =>
  <Grid item xs={6} md={3}>
    <Link underline='none' component={RouterLink} to={to}>
      <Card elevation={4}>
        <Button size="large" color="primary" fullWidth style={{minHeight: '4em'}}>
          {label}
        </Button>
      </Card>
    </Link>
  </Grid>;

const ExperimentsList = () => <Box mt={4}>
  <Typography variant="h5" color="secondary" gutterBottom>
    experiments
  </Typography>
  <Grid container spacing={4}>
    <ExperimentCard label='AHEAD' to='/ahead'/>
    <ExperimentCard label='Conversation Transform' to='/transform'/>
    <Grid item xs={12} md={6}/>
    <ExperimentCard label='NLP Similarity' to='/query'/>
    <ExperimentCard label='Timer (redacted)' to='#'/>
  </Grid>
</Box>;

/**
 * Main Layout, almost everything (Julia Viewer excluded) is in this layout
 */
export function Application() {
  const classes = appStyles();
  return <>
    <TopBar/>

    <Container maxWidth="lg" className={classes.container}>
      <Box paddingTop={2}/>

      <Switch>
        {/* Ahead */}
        <Route path="/ahead">
          <AheadLayout/>
        </Route>

        {/* Conversation Transform */}
        <Route path="/transform">
          <ConversationTransformLayout/>
        </Route>

        {/* NLP Similarity */}
        <Route path="/query">
          <QueryLayout/>
        </Route>

        {/* Timer Editor */}
        <Route path="/julia">
          <TimerEditorLayout/>
        </Route>

        {/* /: Finance components */}
        <Route>
          <WelcomeMessage classes={classes}/>
          <FinanceLayout/>
          <ExperimentsList/>
          <Box width={0.4} mt={-1} mb={-7} ml="auto" mr="auto">
            <Lottie options={{animationData: animationMarket}}/>
          </Box>
        </Route>
      </Switch>
      <Footer/>
    </Container>
  </>;
}
