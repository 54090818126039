import {err, log} from "./utils";

const SUPPORTED = 'webkitSpeechRecognition' in window;
const DEBUG = false;

export interface WebSpeechCallbacks {
  status: (status: WebSpeechStatusType) => any,
  error: (message: string) => any,
  transcript: (transcript: WebSpeechTranscriptType) => any,
}

export interface WebSpeechStatusType {
  supported: boolean,   // whether the API is supported at all
  dialect: string,      // e.g. 'en-US' set by the constructor

  started: boolean,     // client state
  recognizing: boolean, // async start state (between onStart and onEnd)

  audioOn: boolean,     // audio open
  soundOn: boolean,     // sound detected
  speechOn: boolean,    // speech detected
}

export interface WebSpeechTranscriptType {
  interim: string,      // in-progress
  final: string[],      // portion finalized (since the start)
}


class WebSpeechUtils {
  private _callbacks: WebSpeechCallbacks;
  private readonly _status: WebSpeechStatusType;
  private transcript: string[] = [];
  private tsStart: number = 0;
  private tsRecognition: number = 0;
  private recognition: SpeechRecognition;

  constructor(currentDialect = 'en-US') {
    this._status = {
      supported: SUPPORTED,
      dialect: currentDialect,
      started: false,
      recognizing: false,
      soundOn: false,
      audioOn: false,
      speechOn: false,
    }
  }

  get statusX() {
    return this._status;
  }

  set callbacks(callbacks: WebSpeechCallbacks) {
    this._callbacks = callbacks;
  }

  start() {
    if (this._status.started)
      return err('WebSpeechUtils: already started');

    // first-time setup
    if (!this.recognition)
      if (!this.createEngine())
        return this.notifyError('Cannot access the speech recognition engine');

    // reset state
    this.transcript = [];
    this._status.started = true;
    this.tsStart = new Event('dummy').timeStamp;
    this.notifyError(null);
    this.notifyStatus();

    // per-run go
    this.recognition.lang = this._status.dialect;
    this.recognition.start();
  }

  stop() {
    if (this.recognition)
      this.recognition.stop();

    // reset state
    this._status.started = false;
    this.tsStart = 0;
    this.notifyStatus();

    // perform STOP operations? maybe in onEnd
  }

  // PRIVATE: engine logic

  private createEngine(): boolean {
    // create the engine
    // noinspection JSPotentiallyInvalidConstructorUsage
    this.recognition = new (window as WSWindow & typeof globalThis).webkitSpeechRecognition();
    if (!this.recognition) return false;

    // setup recognition (first time)
    this.recognition.continuous = true;
    this.recognition.interimResults = true;

    this.recognition.onstart = ev => this.onRecognitionStart(ev);
    this.recognition.onerror = ev => this.onRecognitionError(ev);
    this.recognition.onend = () => this.onRecognitionEnd();
    this.recognition.onresult = ev => this.onResult(ev);
    this.recognition.onaudiostart = () => {
      this._status.audioOn = true;
      this.notifyStatus();
    };
    this.recognition.onaudioend = () => {
      this._status.audioOn = false;
      this.notifyStatus();
    };
    this.recognition.onsoundstart = () => {
      this._status.soundOn = true;
      this.notifyStatus();
    };
    this.recognition.onsoundend = () => {
      this._status.soundOn = false;
      this.notifyStatus();
    };
    this.recognition.onspeechstart = () => {
      this._status.speechOn = true;
      this.notifyStatus();
    };
    this.recognition.onspeechend = () => {
      this._status.speechOn = false;
      this.notifyStatus();
    };

    // all okay
    return true;
  }

  private onRecognitionStart(ev: Event) {
    // update state
    this._status.recognizing = true;
    this.tsRecognition = ev.timeStamp;
    this.notifyStatus();
  };

  private onRecognitionError(ev: Event) {
    const cause = ev['error'];

    // discard end messages if not recognizing
    if (this._status.recognizing) {
      // log(`received error while started: ${this._status.started} and recognizing: ${this._status.recognizing}. reset recognizing.`);
      this._status.recognizing = false;
      this.notifyStatus();
    }

    switch (cause) {
      case 'no-speech':
        this.notifyError(`No speech detected. Stopped.`);
        break;
      case 'audio-capture':
        this.notifyError(`No microphone available`);
        break;
      case 'not-allowed':
        if (ev.timeStamp - this.tsStart < 100)
          this.notifyError(`Microphone permissions are blocked`);
        else
          this.notifyError(`You need to allow Microphone permissions`);
        break;
      default:
        this.notifyError(`Unhandled error: ${cause}`);
        break;
    }
  }

  private onRecognitionEnd() {
    // const endByUser = this._status.started == false && this._status.recognizing == true;
    const endBeforeOnStart = this._status.started === true && this._status.recognizing === false;
    const autoEnded = this.transcript.length && this._status.started === true && this._status.recognizing === true;

    // if ended automatically, restart it
    if (autoEnded) {
      log('Auto-restart recognition after inactivity');
      this.recognition.start();
      return;
    }

    // unclean state reset
    if (endBeforeOnStart) {
      this._status.started = false;
      this.tsStart = 0;
    }

    // reset state
    this._status.recognizing = false;
    this.tsRecognition = 0;
    this.notifyStatus();

    // perform END ops
    // ...
  }

  private onResult(ev: SpeechRecognitionEvent) {
    let finalizedCurrent = '';
    let transcriptInterim = '';
    for (let i = ev.resultIndex; i < ev.results.length; ++i)
      if (ev.results[i].isFinal)
        finalizedCurrent += ev.results[i][0].transcript;
      else
        transcriptInterim += ev.results[i][0].transcript;

    if (finalizedCurrent)
      this.transcript.push(finalizedCurrent.trim());

    this.notifyTranscript(transcriptInterim, this.transcript);
  }

  private notifyError(message: string) {
    const hasCallback = this._callbacks && this._callbacks.error;
    if (DEBUG && message) err(`WebSpeechUtils: error: ${message} - ${hasCallback ? '[notified]' : '[NOT notified]'}`);
    if (hasCallback)
      this._callbacks.error(message);
  }

  private notifyStatus() {
    if (DEBUG) log(`WebSpeechUtils: status: ${JSON.stringify(this._status)}`);
    const hasCallback = this._callbacks && this._callbacks.status;
    if (hasCallback)
      this._callbacks.status(this._status);
  }

  private notifyTranscript(interim: string, final: string[]) {
    if (DEBUG) log(`WebSpeechUtils: transcript: ${final}${interim ? '[' + interim + ']' : ''}`);
    const hasCallback = this._callbacks && this._callbacks.transcript;
    if (hasCallback)
      this._callbacks.transcript({
        interim: interim,
        final: final,
      });
  }
}

// let typescript know about the possible presence of this method
interface WSWindow extends Window {
  webkitSpeechRecognition?: any,
}

const webSpeechUtils = new WebSpeechUtils();
export {webSpeechUtils};
