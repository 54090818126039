import React from "react";
import {
  Box, Button, Checkbox, Chip, FormControlLabel, Grid, Paper, Switch, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import {Stack, Animation, EventTracker, HoverState} from '@devexpress/dx-react-chart';
import {ArgumentAxis, BarSeries, Chart, Legend, Title, Tooltip, ValueAxis,} from '@devexpress/dx-react-chart-material-ui';

import Papa from 'papaparse';
import fileDownload from 'js-file-download';

import {
  rtttApiClient,
  FinConfigType, FinIpoCalendarEntryType, FinIpoCalendarType,
  FinLastPricesType, FinTradeType
} from "../logic/RtttApiClient";


const useStyles = makeStyles(() => ({
  flexGrow: {
    flexGrow: 1
  },
  spacBadge: {
    backgroundColor: '#fff700',
    border: '1px solid #00000045',
    borderRadius: 6,
    fontSize: '0.8em',
    fontWeight: 600,
    marginRight: 6,
    padding: '1px 5px',
  }
}));

const symbolsStyles = makeStyles((theme) => ({
  symbol: {
    margin: theme.spacing(0.25, 0.5),
  },
}));

const pricesStyles = makeStyles((theme) => ({
  grid: {},
  paper: {
    padding: theme.spacing(1),
    height: '100%',
  },
  symbol: {
    textAlign: 'center',
  },
}));


/** Stock: Live Prices / Trades Stream **/

function TradesLayout() {
  const [finConfig, setFinConfig] = React.useState<FinConfigType>(undefined);

  React.useEffect(() => {
    const configCallback = (config: FinConfigType) => setFinConfig(config);
    rtttApiClient.subFinConfig(configCallback);
    return () => rtttApiClient.unsubFinConfig(configCallback);
  }, []);

  return <>
    <Grid container>
      <Grid item xs={12} md={8}>
        <Typography variant="h6" color="primary" style={{fontWeight: 400}} gutterBottom>
          Live prices
        </Typography>
        <TradesLastPrices/>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h6" color="primary" style={{fontWeight: 400}} gutterBottom>
          Trades stream
        </Typography>
        <TradesStream/>
      </Grid>
    </Grid>

    <Box mt={3}/>
    <Typography variant="body1" style={{fontWeight: 400}} gutterBottom>
      Symbols editor
    </Typography>
    <SymbolsEditor config={finConfig}/>
  </>;
}

/**
 * Editor for the Symbols to be monitored by the backend.
 */
function SymbolsEditor({config}: { config: FinConfigType | undefined }) {
  const classes = symbolsStyles();
  const symbols: string[] | undefined = config && Object.keys(config);

  const [addSymbolName, setAddSymbolName] = React.useState<string>('');
  const [deleteEnabled, setDeleteEnabled] = React.useState<boolean>(false);

  function addSymbol(symbol: string) {
    setAddSymbolName('');
    symbol = symbol.trim().toUpperCase();
    if (symbol.length > 1 && symbol.length < 20)
      rtttApiClient.sendFinAdd(symbol);
  }

  function removeSymbol(symbol: string) {
    symbol = symbol.trim().toUpperCase();
    if (symbol.length > 1 && symbol.length < 20)
      rtttApiClient.sendFinRemove(symbol);
  }

  return <>
    <Box>
      {symbols && symbols.map((ticker: string) =>
        <Chip key={ticker} label={ticker} color="primary" variant={deleteEnabled ? 'outlined' : 'default'} clickable
          // avatar={<Avatar>{ticker.charAt(0).toUpperCase()}</Avatar>}
              onDelete={deleteEnabled ? () => removeSymbol(ticker) : null} /*deleteIcon={<DoneIcon/>}*/
              className={classes.symbol}/>)}
      <Box>
        <FormControlLabel label="Add symbol:" labelPlacement="start" control={
          <TextField id="standard-basic" label="symbol name" placeholder="AAPL" style={{margin: '0 16px'}}
                     value={addSymbolName} onChange={event => setAddSymbolName(event.target.value)}/>
        }/>
        <Button color="primary" size="large" disabled={addSymbolName.length === 0} onClick={() => addSymbol(addSymbolName)}>Add</Button>
        <FormControlLabel label="- Delete symbols:" labelPlacement="start" control={
          <Checkbox color="primary" checked={deleteEnabled} onChange={event => setDeleteEnabled(event.target.checked)}/>
        }/>
      </Box>
    </Box>
  </>
}

/**
 * Current Stock Cards
 */
function TradesLastPrices() {
  const classes = pricesStyles();
  const [prices, setPrices] = React.useState<FinLastPricesType>({});
  const [timedOut, setTimedOut] = React.useState<boolean>(false);

  React.useEffect(() => {
    const pricesCallback = (prices: FinLastPricesType) => setPrices({
      ...prices
    });
    rtttApiClient.subFinPrices(pricesCallback);
    return () => rtttApiClient.unsubFinPrices(pricesCallback);
  }, []);

  React.useEffect(() => {
    const timerId = setTimeout(() => setTimedOut(true), 4000);
    return () => clearTimeout(timerId);
  }, []);

  if (Object.keys(prices).length === 0) return (
    <Typography>&nbsp; {timedOut ? 'API/market currently down. Working on it.' : 'Loading ...'}</Typography>);

  return (
    <Box>
      <Grid container direction="row" justify="space-evenly" alignItems="stretch" spacing={1}>
        {Object.keys(prices).sort().map(symbol =>
          <Grid item xs={12} sm={6} md={4} className={classes.grid} key={symbol}>
            <Paper elevation={4} className={classes.paper}>
              <Typography variant="h5" color="primary" className={classes.symbol}>
                {symbol}
              </Typography>
              <Typography variant="h6" color="textPrimary">
                <span style={{color: 'gray'}}>last:</span>
                &nbsp;{Math.round(prices[symbol] * 100) / 100}
                <span style={{color: 'gray'}}> $</span>
              </Typography>
              <Typography variant="body2" color="textPrimary">
                <span style={{color: 'gray'}}>market cap: TBA</span>
              </Typography>
              <Typography variant="body2" color="textPrimary">
                <span style={{color: 'gray'}}>P/E: TBA, P/S: TBA</span>
              </Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

/**
 * Visual stream of Trades
 */
function TradesStream() {
  const [trades, setTrades] = React.useState<FinTradeType[]>([]);
  const [total, setTotal] = React.useState<number>(0);

  React.useEffect(() => {
    const tradeCallback = (trades: FinTradeType[], total: number) => {
      setTrades(trades.slice().reverse());
      setTotal(total);
    }
    rtttApiClient.subFinTrades(tradeCallback);
    return () => rtttApiClient.unsubFinTrades(tradeCallback);
  }, []);

  return (
    <Box>
      {trades.map(trade =>
        <Typography variant="body1" key={trade.seq}>
          <span style={{color: trade.dir === 1 ? 'green' : (trade.dir === -1 ? 'darkred' : 'black')}}>
            &nbsp; {trade.s}: {trade.p} <span style={{color: 'lightgray'}}>({trade.v})</span>
          </span>
        </Typography>)}
      <Typography>&nbsp; {total > 1 ? ` ${total} trades` : 'Waiting...'}</Typography>
    </Box>
  );
}


/** IPO Calendar **/

function IPOTable({classes, calendarEntries}: { classes, calendarEntries: FinIpoCalendarEntryType[] }) {
  const styleForRow = (entry: FinIpoCalendarEntryType) => {
    const statusToColor = {
      filed: '#f5f5f5',
      withdrawn: 'lightred',
      expected: 'lightyellow',
      priced: '#dfffdf',
    };
    return entry.status in statusToColor ? {background: statusToColor[entry.status]} : {};
  };
  return <TableContainer component={Paper} elevation={3}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell><Typography>Companies</Typography></TableCell>
          <TableCell align="center">Date</TableCell>
          <TableCell align="left">Exchange</TableCell>
          <TableCell align="center">Status</TableCell>
          <TableCell align="right">Raise ($M)</TableCell>
          <TableCell align="right">Price ($)</TableCell>
          <TableCell align="right">Offered (M)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {calendarEntries.map((entry, idx) => (
          <TableRow key={'ipo-' + idx} style={styleForRow(entry)}>
            <TableCell component="th" scope="row">
              {entry.isSPAC && <span className={classes.spacBadge}>SPAC</span>}
              {entry.name}{entry.symbol && <b> {entry.symbol}</b>}
            </TableCell>
            <TableCell align="center">{entry.date.slice(5)}</TableCell>
            <TableCell align="left">{entry.exchange ? entry.exchange.slice(0, 14) : ''}</TableCell>
            <TableCell align="center">{entry.status}</TableCell>
            <TableCell align="right">{Math.round(entry.totalSharesValue / 1000000)}</TableCell>
            <TableCell align="right">{entry.price}</TableCell>
            <TableCell align="right">{entry.numberOfShares ? Math.round(entry.numberOfShares / 1000000) : ''}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>;
}

interface ChartDataType {
  b: string,  // bucket
  s: number,  // count of spacs
  c: number,  // count of non-spacs
  sr: number, // raise for spacs
  cr: number, // raise for companies
  t: number,  // total count
}

function IPOChart({classes, showRaised, calendarEntries}: { classes: any, showRaised, calendarEntries: FinIpoCalendarEntryType[] }) {
  // transform data to a plottable style by https://devexpress.github.io/devextreme-reactive/react/chart/
  const data: ChartDataType[] = [];

  // pivot to a monthly bucket (using yyyy-mm (7 chars))
  for (let i = calendarEntries.length - 1; i >= 0; i--) {
    const entry = calendarEntries[i];
    // exclude some entries
    if (entry.status === 'withdrawn')
      continue;
    const bucketName = entry.date.slice(0, 7); // 'yyyy-mm'
    // use an existing monthly bucket, or create an empty one
    let bucket: ChartDataType = data.find(d => d.b == bucketName);
    if (!bucket) {
      bucket = {
        b: bucketName,
        s: 0,
        c: 0,
        sr: 0,
        cr: 0,
        t: 0,
      }
      data.push(bucket);
    }
    // update counters
    if (entry.isSPAC) {
      bucket.s++;
      bucket.sr += isFinite(entry.totalSharesValue) ? entry.totalSharesValue : 0;
    } else {
      bucket.c++;
      bucket.cr += isFinite(entry.totalSharesValue) ? entry.totalSharesValue : 0;
    }
    bucket.t++;
  }

  // go and change the labels, to shrink appearance
  // const monthMapper = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  data.forEach((bucket, bIdx) => {
    // const monIdx = parseInt(bucket.b.slice(5, 7));
    // bucket.b = monthMapper[monIdx] + '-' + bucket.b.slice(2, 4);
    // bucket.b = bucket.b.slice(2, 4) + '-' + monthMapper[monIdx];
    bucket.b = bucket.b.slice(2, 4) + '-' + bucket.b.slice(5, 7);
  });

  // change the Y axis to Millions if showing Raised money
  if (showRaised)
    data.forEach(bucket => {
      bucket.sr = Math.round(bucket.sr / 1E+06);
      bucket.cr = Math.round(bucket.cr / 1E+06);
    });

  // render chart using devextreme-reactive
  return <Chart data={data}>
    <ArgumentAxis/>
    <ValueAxis/>
    <BarSeries name="SPACs" argumentField="b" valueField={showRaised ? 'sr' : 's'} color="#d99900"/>
    <BarSeries name="Companies" argumentField="b" valueField={showRaised ? 'cr' : 'c'} color="#00a935"/>
    <Animation/>
    <Legend/>
    <EventTracker/>
    <HoverState/>
    <Tooltip/>
    <Title text="Number of monthly IPOs (incl. filings) for SPACs vs Companies"/>
    <Stack/>
  </Chart>;
}

function IPOCalendar({classes}) {
  // UI state
  const [filterText, setFilterText] = React.useState<string>('');
  const [hideSPACs, setHideSPACs] = React.useState<boolean>(false);
  // const [chartCount, setChartCount] = React.useState<boolean>(true);
  const [showTable, setShowTable] = React.useState<boolean>(true);
  const [onlyCurrYear, setOnlyCurrYear] = React.useState<boolean>(false);

  // server state
  const [ipoCalendar, setIpoCalendar] = React.useState<FinIpoCalendarType>(undefined);
  React.useEffect(() => {
    const callback = (cal: FinIpoCalendarType) => setIpoCalendar(cal);
    rtttApiClient.subFinIpoCalendar(callback);
    return () => rtttApiClient.unsubFinIpoCalendar(callback);
  }, []);

  if (!ipoCalendar) return <Typography>⌛ Updating IPO calendar ...</Typography>;

  // FILTERING
  let calendarEntries = ipoCalendar.calendar;

  // 2021 filtering
  const currYear = '' + (new Date).getFullYear();
  if (onlyCurrYear)
    calendarEntries = calendarEntries.filter(e => e.date.includes(currYear));

  // SPAC filtering
  if (hideSPACs)
    calendarEntries = calendarEntries.filter(e => !e.isSPAC);

  // text filtering
  if (filterText && filterText.length > 2) {
    const lcFilter = filterText.toLowerCase();
    calendarEntries = calendarEntries.filter(e => e.name.toLowerCase().includes(lcFilter));
  }

  const downloadCSV = () => {
    // csv-friendly column names
    const mapper = {
      Name: 'name',
      Symbol: 'symbol',
      Date: 'date',
      Exchange: 'exchange',
      Status: 'status',
      Raise: 'totalSharesValue',
      Price: 'price',
      Offered: 'numberOfShares',
      SPAC: 'isSPAC'
    };
    const columns = Object.keys(mapper);
    const mappedEntries = calendarEntries.map(entry => {
      const mappedEntry = {};
      for (let column of columns)
        mappedEntry[column] = entry[mapper[column]];
      return mappedEntry;
    });
    // download the file to the browser
    const csvString = Papa.unparse(mappedEntries, {columns});
    fileDownload(csvString, 'rttt-ipo-cal.csv');
  }

  return <>
    {/* IPO Table Controls */}
    <Box mt={2} mb={3}>
      <Grid container spacing={1}>
        <Grid item sm={2}>
          <Typography variant="body1">Initial Public offerings</Typography>
          <Typography variant="caption">
            {onlyCurrYear ? 'In ' + currYear : 'Since Feb 2019'}&nbsp;
            (count: {calendarEntries && calendarEntries.length})
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <TextField label="filter offerings" placeholder="Company..." type="text" fullWidth style={{marginTop: '-12px'}}
                     value={filterText} onChange={e => setFilterText(e.target.value)}/>
        </Grid>
        <Grid item sm={3} style={{textAlign: 'center'}}>
          <FormControlLabel label={'Just ' + currYear} control={
            <Switch color="primary" checked={onlyCurrYear} onChange={event => setOnlyCurrYear(event.target.checked)}/>
          }/>
          <FormControlLabel label="Hide SPACs" control={
            <Switch color="primary" checked={hideSPACs} onChange={event => setHideSPACs(event.target.checked)}/>
          }/>
        </Grid>
        <Grid item sm={3} style={{textAlign: 'center'}}>
          {/*<FormControlLabel label={chartCount ? 'Count' : 'Raised'} control={*/}
          {/*  <Switch color="primary" checked={chartCount} onChange={event => setChartCount(event.target.checked)}/>*/}
          {/*}/>*/}
          <FormControlLabel label="Table" control={
            <Switch color="primary" checked={showTable} onChange={event => setShowTable(event.target.checked)}/>
          }/>
        </Grid>
        <Grid item sm={2}>
          <Button variant="contained" color="primary" onClick={downloadCSV}>Download CSV <ArrowDownwardIcon fontSize="inherit"/></Button>
        </Grid>
      </Grid>
    </Box>

    {/* Chart */}
    <Box mb={2}>
      <Paper elevation={3}>
        <IPOChart classes={classes} showRaised={false} calendarEntries={calendarEntries}/>
      </Paper>
    </Box>

    {/* Table */}
    {showTable && <Box>
      <IPOTable classes={classes} calendarEntries={calendarEntries}/>
    </Box>}
  </>;
}


/** The full Finance layout  **/

export function FinanceLayout() {
  const classes = useStyles();

  // UI state
  const [showIPOCalendar, setShowIPOCalendar] = React.useState<boolean>(true);
  const [showTrades, setShowTrades] = React.useState<boolean>(false);

  // server state
  const [connected, setConnected] = React.useState<boolean>(false);
  React.useEffect(() => {
    const connectCallback = (on: boolean) => setConnected(on);
    rtttApiClient.subToConnected(connectCallback);
    return () => rtttApiClient.unsubFromConnected(connectCallback);
  });

  return <main>
    {/* IPO Calendar */}
    <Box mt={2} display="flex" alignItems="baseline">
      <Switch checked={showIPOCalendar} onChange={e => setShowIPOCalendar(e.target.checked)} color="primary"/>
      <Box flexGrow={1}>
        <Typography variant="h5" display="inline" color="primary">
          IPOs & SPACs
        </Typography>
      </Box>
    </Box>
    {connected && showIPOCalendar && <IPOCalendar classes={classes}/>}
    {!connected && <Typography color="error">Server Disconnected.</Typography>}

    {/* Trades */}
    <Box mt={4} display="flex" alignItems="baseline">
      <Switch checked={showTrades} onChange={e => setShowTrades(e.target.checked)} color="primary"/>
      <Typography variant="h5" display="inline" color="primary" className={classes.flexGrow}>
        Live Trades
      </Typography>
    </Box>
    {connected && showTrades && <TradesLayout/>}
    {!connected && <Typography color="error">Server Disconnected.</Typography>}
  </main>;
}
