import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  Box, Button, ButtonGroup, FormControlLabel, Grid, Link, MenuItem,
  Paper, Select, Switch, TextField, Tooltip,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PauseIcon from '@material-ui/icons/Pause';
import RemoveIcon from '@material-ui/icons/Remove';
import ResumeIcon from '@material-ui/icons/FastForward';
import SendIcon from '@material-ui/icons/Send';
import StopIcon from '@material-ui/icons/Stop';

import clsx from "clsx";

import {EXPIRED_EMOJIS, TimerAgendaItemType, TimerAgendaType, TimerScene, TimerStatusType, rtttApiClient,} from "../logic/RtttApiClient";
import {TimerViewLayout} from "./TimerViewLayout";


const useStyles = makeStyles((theme) => ({
  gridContainers: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
  },
  separatorLineMain: {
    borderTop: '1px solid',
    borderTopColor: theme.palette.primary.main,
    marginBottom: '28px',
  },
  paneTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    flexGrow: 1,
  },
  paperMargin: {
    padding: theme.spacing(2),
  },
  previewContainer: {
    position: 'relative',
    height: '600px',
    zoom: 0.4,
  },
  agendaActive: {
    backgroundColor: 'rgba(246,203,92,0.5)',
  },
  agendaInactive: {},
}));

interface HMSType {
  h: number,
  m: number,
  s: number,
}

const getHMS = (h, m, s): HMSType => ({h, m, s});
const HMStoS = (hms: HMSType) => hms.s + 60 * (hms.m + 60 * hms.h);


/** Preference Components **/

const PrefLabel = (props: { title: string, subTitle: string, disabled?: boolean }) =>
  <Grid item xs={12} sm={5} style={props.disabled ? {color: 'lightgray'} : {}}>
    <Typography variant="body1">{props.title}</Typography>
    {props.subTitle && <Typography variant="caption">{props.subTitle}</Typography>}
  </Grid>;

const PrefHMS = ({hms, setHMS}) => {
  const handleChange = (prop) => (event) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value))
      setHMS({...hms, [prop]: Math.max(0, value)});
  }
  return <Grid item xs={12} sm={7}>
    <TextField value={hms.h} label="Hours" type="number"
               style={{width: 70}} inputProps={{style: {textAlign: 'center'}}}
               onChange={handleChange('h')}/>
    <TextField value={hms.m} label="Minutes" type="number" color="secondary"
               style={{width: 70}} inputProps={{style: {textAlign: 'center'}}}
               onChange={handleChange('m')}/>
    <TextField value={hms.s} label="Seconds" type="number"
               style={{width: 70}} inputProps={{style: {textAlign: 'center'}}}
               onChange={handleChange('s')}/>
  </Grid>;
};

const PrefButtonInt = ({names, int, setInt, color}) =>
  <Grid item xs={12} sm={7}>
    <ButtonGroup disableElevation variant="contained">
      {names.map((name, idx) =>
        <Button key={idx} color={idx == int ? color : "default"} style={{
          minWidth: names.length < 5 ? '60px' : '40px',
          // marginRight: idx == 2 ? '6px' : 0
        }} onClick={() => setInt(idx)}>
          {name}
        </Button>)}
    </ButtonGroup>
  </Grid>;

const PrefString = ({str, setStr, updateCb}) =>
  <Grid item xs={12} sm={7}>
    <TextField value={str} type="text" onChange={event => setStr(event.target.value)}/>
    <Button onClick={updateCb}>Update</Button>
  </Grid>;


/** Shared Components **/

function PaneSwitch({classes, title, switchLabel, defaultState, children, stayOpen}) {
  const [showPane, setShowPane] = React.useState<boolean>(defaultState);
  return <>
    <Box display="flex" alignItems="baseline">
      <Typography color="primary" variant="h6" className={classes.paneTitle}>
        {title}
      </Typography>
      {!(showPane && stayOpen) &&
      <FormControlLabel label={switchLabel} control={
        <Switch color="primary" checked={showPane} onChange={event => setShowPane(event.target.checked)}/>
      }/>}
    </Box>
    <Paper className={classes.paperMargin} style={{backgroundColor: showPane ? 'transparent' : '#e6efe9'}} elevation={4}>
      {showPane && children}
    </Paper>
  </>;
}


/** Preview **/

function PreviewPane(props: { classes, showStop: boolean, onStopClicked: () => void }) {
  return <PaneSwitch classes={props.classes} title="Preview" switchLabel="Show" defaultState={true} stayOpen={false}>
    <Grid container className={props.classes.gridContainers} spacing={3}>
      <Grid id="preview-container" item xs={12} className={props.classes.previewContainer} style={{padding: 0}}>
        {/*<iframe frameBorder={0} width="100%" height="100%" src='/julia/view'/>*/}
        <TimerViewLayout/>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Button variant="outlined" onClick={() => window.open('/julia/view', "_blank")}>
          Open in new window...
        </Button>
      </Grid>
      {props.showStop && <Grid item xs={12} sm={4} style={{textAlign: "right"}}>
        <Button variant="outlined" color="primary" endIcon={<StopIcon/>} onClick={props.onStopClicked}>
          Stop
        </Button>
      </Grid>}
    </Grid>
  </PaneSwitch>;
}


/** Planner **/

const agendaDurations = [30, 25, 20, 15, 13, 10, 8, 7, 6, 5, 4, 3, 2, 1, 0.5, 0.25];
const durationToIdx = (duration: number) => {
  const min = duration / 60;
  return agendaDurations.findIndex(e => e == min);
}

const PlannerItem = ({classes, item, active, itemUpdate, itemStart}: {
  classes, item: TimerAgendaItemType, active: boolean,
  itemUpdate: (update: Partial<TimerAgendaItemType>) => void, itemStart: () => void
}) => {
  // properties (from the server)
  const {label: serverLabel, duration: serverDuration, started: serverStarted} = item;
  const serverDurationId = durationToIdx(serverDuration);

  // local label (to remove issues from server delays)
  const [localLabel, setLocalLabel] = React.useState<string>('');
  const [coalesceTimerId, setCoalesceTimerId] = React.useState(null);

  const labelUpdate_coalesced = (fieldLabel: string) => {
    // also update the local label
    setLocalLabel(fieldLabel);
    // coalesced timer operation, with local state tracking
    if (coalesceTimerId)
      clearTimeout(coalesceTimerId);
    setCoalesceTimerId(setTimeout(() => {
      itemUpdate({'label': fieldLabel});
      setCoalesceTimerId(null);
    }, 400));
  }
  const updateDuration = (durationId: number) => {
    if (durationId < 0 || durationId >= agendaDurations.length) return;
    itemUpdate({'duration': 60 * agendaDurations[durationId]});
  }

  // trick to absorb the value from the server until we have a local value
  let textValue = coalesceTimerId ? localLabel : serverLabel;

  return <Grid container className={clsx(classes.gridContainers, active ? classes.agendaActive : classes.agendaInactive)} spacing={1}>
    <Grid item xs={6}>
      <TextField type="text" placeholder="desc..." fullWidth
                 value={textValue ? textValue : ''}
                 onChange={event => labelUpdate_coalesced(event.target.value)}/>
    </Grid>
    <Grid item xs={3}>
      <Select fullWidth value={serverDurationId} onChange={event => updateDuration(parseInt(event.target.value as string))}>
        {agendaDurations.map((duration, idx) =>
          <MenuItem key={idx} value={idx}>{duration >= 1 ? `${duration} min` : `${duration * 60} sec`}</MenuItem>)}
      </Select>
    </Grid>
    <Grid item xs={3}>
      <Button variant="contained" size="small" color={serverStarted ? "default" : "primary"} endIcon={<SendIcon/>} onClick={itemStart}>
        Start
      </Button>
    </Grid>
  </Grid>;
};

function PlannerPane({classes}) {
  // local state
  const [_agenda, _setAgenda] = React.useState<TimerAgendaType>();

  // sync agenda with the server
  React.useEffect(() => {
    const callback = (agenda: TimerAgendaType) => _setAgenda(agenda);
    rtttApiClient.subTimerAgenda(callback);
    return () => rtttApiClient.unsubTimerAgenda(callback);
  }, []);

  if (!_agenda || !_agenda.items) return null;

  // agenda configuration and operations
  const addAgendaItem = () => rtttApiClient.sendTimerAgendaCommand('agenda/add-last');
  const removeAgendaItem = () => rtttApiClient.sendTimerAgendaCommand('agenda/remove-last');
  const clearAgenda = () => rtttApiClient.sendTimerAgendaCommand('agenda/clear');
  const updateAgendaItem = (idx: number, update: Partial<TimerAgendaItemType>) => rtttApiClient.sendTimerAgendaUpdate(idx, update);
  const startAgendaItem = (idx: number) => rtttApiClient.sendTimerAgendaCommand('agenda/start', idx);

  const itemsCount = _agenda.items.length;

  return <PaneSwitch classes={classes} title="Meeting Planner" switchLabel="Plan Ahead" defaultState={true} stayOpen={false}>
    {_agenda.items.map((item, idx) =>
      <PlannerItem key={idx.toString()} item={item} classes={classes} active={idx === _agenda.activeIdx}
                   itemUpdate={update => updateAgendaItem(idx, update)}
                   itemStart={() => startAgendaItem(idx)}/>)}
    <Box display="flex" alignItems="center">
      {itemsCount < 20 && <Box display="inline">
        <Button color="secondary" variant="outlined" startIcon={<AddIcon/>} onClick={addAgendaItem}>Add</Button>
      </Box>}
      {itemsCount > 1 && <Box display="inline" ml={1}>
        <Button color="secondary" variant="outlined" endIcon={<RemoveIcon/>} onClick={removeAgendaItem}>Del</Button>
      </Box>}
      {itemsCount > 0 && <Box display="inline" ml={1}>
        <Button color="primary" variant="outlined" onClick={clearAgenda}>Clear</Button>
      </Box>}
      <Box display="inline" flexGrow={1} textAlign="right">
        <Tooltip title={<Typography>The 'warning' happens when 1/5th of the time is left, the 'alert' when 1/15 is left.</Typography>}>
          <InfoOutlinedIcon color="disabled"/>
        </Tooltip>
      </Box>
    </Box>
  </PaneSwitch>;
}


/** Timer Editor main layout **/

export function TimerEditorLayout() {
  const classes = useStyles();

  // client-only state
  const [countdown, setCountdown] = React.useState<HMSType>(getHMS(0, 5, 0));
  const [warning, setWarning] = React.useState<HMSType>(getHMS(0, 1, 0));
  const [alert, setAlert] = React.useState<HMSType>(getHMS(0, 0, 20));

  // server-retrieved state
  const [scene, setScene] = React.useState<number>(0);
  const [theme, setTheme] = React.useState<number>(0);
  const [logoText, setLogoText] = React.useState<string>('');
  const [zeroBehavior, setZeroBehavior] = React.useState<number>(0);
  const [progressBar, setProgressBar] = React.useState<number>(0);
  const [started, setStarted] = React.useState<boolean>(false);
  const [paused, setPaused] = React.useState<boolean>(false);

  React.useEffect(() => {
    const callback = (status: TimerStatusType) => {
      setScene(status.scene);
      setTheme(status.theme);
      setLogoText(status.logoText);
      setZeroBehavior(status.zeroBehavior);
      setProgressBar(status.progressBar);
      setStarted(status.started);
      setPaused(status.paused);
    };
    rtttApiClient.subTimerStatus(callback);
    return () => rtttApiClient.unsubTimerStatus(callback);
  }, []);

  const changeScene = (idx: number) => rtttApiClient.sendTimerConfigUpdate({scene: idx});
  const changeTheme = (idx: number) => rtttApiClient.sendTimerConfigUpdate({theme: idx});
  const changeLogoText = () => rtttApiClient.sendTimerConfigUpdate({logoText: logoText});
  const changeZeroBehavior = (idx: number) => rtttApiClient.sendTimerConfigUpdate({
    zeroBehavior: idx,
    zeroEmojiIndex: Math.floor(Math.random() * EXPIRED_EMOJIS.length),
  });
  const changeProgressBar = (idx: number) => rtttApiClient.sendTimerConfigUpdate({progressBar: idx});
  const startTimer = (duration: number, warning: number, alert: number, label ?: string) => {
    rtttApiClient.sendTimerConfigUpdate({
      t_duration: duration,
      t_warning: warning,
      t_alert: alert,
      timerText: label != undefined ? label : null,
    });
    rtttApiClient.sendTimerCommand('start');
  }
  const startManualTimer = () => startTimer(HMStoS(countdown), HMStoS(warning), HMStoS(alert));
  const stopTimer = () => rtttApiClient.sendTimerCommand('stop');
  const pauseTimer = () => rtttApiClient.sendTimerCommand('pause');
  const resumeTimer = () => rtttApiClient.sendTimerCommand('resume');

  return <main>
    {/* Split horizontally in 2 */}
    <Grid container className={classes.gridContainers} spacing={3}>

      {/* Left Pane: Controls */}
      <Grid item xs={12} md={7}>
        <Typography color="primary" variant="h6" className={classes.paneTitle}>
          Preferences
        </Typography>
        <Paper className={classes.paperMargin} elevation={4}>
          {/* Settings */}
          <Grid container className={classes.gridContainers} spacing={3}>
            <PrefLabel title="Global ON/OFF" subTitle="Change what is shown on screen"/>
            <PrefButtonInt names={['logo', 'timer', '📅', 'blank']} int={scene} setInt={changeScene} color={"primary"}/>
          </Grid>
          <Grid container className={classes.gridContainers} spacing={3}>
            <PrefLabel title="LOGO Text" subTitle="Welcome text under the logo" disabled={scene > 0}/>
            <PrefString str={logoText} setStr={setLogoText} updateCb={changeLogoText}/>
          </Grid>
          <Grid container className={classes.gridContainers} spacing={3}>
            <PrefLabel title="Theme" subTitle="Color of the countdown"/>
            <PrefButtonInt names={['Dark', 'Light']} int={theme} setInt={changeTheme} color="secondary"/>
          </Grid>
        </Paper>

        <Typography color="primary" variant="h6" className={classes.paneTitle}>
          Timer Control
        </Typography>
        <Paper className={classes.paperMargin} elevation={4}>
          {/*<Box className={classes.separatorLineMain}/>*/}
          <Grid container className={classes.gridContainers} spacing={3}>
            <PrefLabel title="Countdown" subTitle="Manual timer duration"/>
            <PrefHMS hms={countdown} setHMS={setCountdown}/>
          </Grid>
          <Grid container className={classes.gridContainers} spacing={3}>
            <PrefLabel title="Warning" subTitle="When to show a 'warning' color"/>
            <PrefHMS hms={warning} setHMS={setWarning}/>
          </Grid>
          <Grid container className={classes.gridContainers} spacing={3}>
            <PrefLabel title="Alert" subTitle="When to show an 'alert' color"/>
            <PrefHMS hms={alert} setHMS={setAlert}/>
          </Grid>
          <Grid container className={classes.gridContainers} spacing={3}>
            <PrefLabel title="Zero Behavior" subTitle="What to show below zero"/>
            <PrefButtonInt names={[' + ', ' - ', ' 0 ', 'q&a', '💸']}
                           int={zeroBehavior} setInt={changeZeroBehavior} color="secondary"/>
          </Grid>
          <Grid container className={classes.gridContainers} spacing={3}>
            <PrefLabel title="Progress Bar" subTitle="Optional progress indicator"/>
            <PrefButtonInt names={['Off', '▼', '▲', 'ᐊ', 'ᐅ']} int={progressBar} setInt={changeProgressBar} color="secondary"/>
          </Grid>
          {/* Controls */}
          <Grid container className={classes.gridContainers} spacing={3}>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="primary" endIcon={<SendIcon/>} onClick={startManualTimer}>
                {started ? 'Restart Timer' : 'Start Timer'}
              </Button>
            </Grid>
            {(scene == TimerScene.Timer && started) && <Grid item xs={12} sm={6}>
              {paused == false && <Button variant="outlined" color="primary" endIcon={<PauseIcon/>} onClick={pauseTimer}>
                Pause
              </Button>}
              {paused == true && <Button variant="outlined" color="primary" endIcon={<ResumeIcon/>} onClick={resumeTimer}>
                Resume
              </Button>}
              <Button variant="outlined" color="primary" endIcon={<StopIcon/>} onClick={stopTimer} style={{marginLeft: '8px'}}>
                Stop
              </Button>
            </Grid>}
          </Grid>
        </Paper> {/* Controls paper */}
      </Grid> {/* Controls pane */}

      {/* Right Pane */}
      <Grid item xs={12} md={5}>
        <PreviewPane classes={classes}
                     showStop={(scene == TimerScene.Timer || scene == TimerScene.Agenda) && started}
                     onStopClicked={stopTimer}/>
        <Box mt={2}/>
        <PlannerPane classes={classes}/>
      </Grid>
    </Grid> {/* left/right split */}

    <br/>
    <Typography variant="caption" gutterBottom>
      Single admin: this control panel is not designed for multiple administrators.
      Another person using this admin console may change its settings at any time.
    </Typography>
    <br/>
    <Typography variant="caption" gutterBottom>
      Multiple displays: the timer is viewable by any number of simultaneous user, just point any browser to: &nbsp;
      <Link href="https://www.racetothetrillion.com/julia/view" target="_blank">https://www.racetothetrillion.com/julia/view</Link>
    </Typography>
  </main>;
}
