import React from "react";
// import {makeStyles} from "@material-ui/core/styles";
import {Box, Button, CircularProgress, Link, TextField, Typography} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";

import md5 from 'js-md5';
import Papa from "papaparse";
import Dropzone from 'react-dropzone';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';

import {ConversationTransformResultType, rtttApiClient} from "../logic/RtttApiClient";
import {ConversationItemType, ExampleData, SentenceSimilarityItemType, SourceDataType, TransformType} from "../logic/ConversationTransform";

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     padding: theme.spacing(2),
//   },
// }));

export interface TextConversationItemType extends ConversationItemType {
  fn?: number,
  ln?: number,
}


export function ConversationTransformLayout() {
  // const classes = useStyles();
  // configuration
  const [demoKey, setDemoKey] = React.useState<string>('');
  const [sourceData, setSourceData] = React.useState<SourceDataType>(ExampleData.Source);
  const [transform0Config, setTransform0Config] = React.useState<TransformType>(ExampleData.Transform0);
  const [transcriptionJSON, setTranscriptionJSON] = React.useState<string>('');
  // runtime
  const [transformPending, setTransformPending] = React.useState(false);
  const [transformResult, setTransformResult] = React.useState<ConversationTransformResultType>(undefined);
  const [transcriptionResult, setTranscriptionResult] = React.useState<object>(undefined);

  // listen for updated results
  React.useEffect(() => {
    const resultCallback = (result: ConversationTransformResultType) => {
      setTransformPending(false);
      setTransformResult(result);
    }
    rtttApiClient.subConversationTransformResult(resultCallback);
    return () => rtttApiClient.unsubConversationTransformResult(resultCallback);
  }, []);

  // listen for updated results
  React.useEffect(() => {
    const resultCallback = result => setTranscriptionResult(result);
    rtttApiClient.subConversationTranscribeResult(resultCallback);
    return () => rtttApiClient.unsubConversationTranscribeResult(resultCallback);
  }, []);

  // operate on the results
  let res_out = undefined;
  if (transformResult) {
    res_out = Object.assign({}, transformResult)
    delete res_out['input'];
  }

  const performTransform = () => {
    setTransformPending(true);
    setTransformResult(undefined);
    rtttApiClient.sendConversationTransformInline({
      source: sourceData,
      transforms: [transform0Config],
    });
  };

  const loadDemoData = async () => {
    const filePrefix: string = md5(demoKey).slice(0, 8);
    const fileSource = '/demo-data/' + filePrefix + '-source.txt';
    const fileKernel = '/demo-data/' + filePrefix + '-kernel.csv';

    // load demo files
    const textSource = await fetch(fileSource).then(response => response.text());
    const textKernel = await fetch(fileKernel).then(response => response.text());
    if (!textSource || !textKernel || textSource.length < 10 || textKernel.length < 10 ||
      textSource.startsWith('<') || textKernel.startsWith('<'))
      return alert('Invalid demo key');

    // load demo data
    await loadTransform0KernelData(textKernel);
    await loadAsSourceData([textSource]);
  }

  const loadTransform0KernelData = async (fileContent: string) => {
    const parsed = Papa.parse<string[]>(fileContent);
    if (parsed.errors.length) {
      console.log(parsed.errors);
      return alert('Errors parsing CSV - see console');
    }

    // create the new Transform object, starting from the default
    const newTransform0Kernel = []

    // process data
    const data: string[][] = parsed.data;
    const dataHeader = data.shift();
    const dataColumnsCount = dataHeader.length;
    const dataRowsCount = data.length;
    for (let rowIdx = 0; rowIdx < dataRowsCount; ++rowIdx) {
      const dataRow = data[rowIdx];
      if (dataRow.length !== dataColumnsCount) {
        if (dataRow.length !== 1 || dataRow[0] !== '') {
          console.log('CSV parse: unexpected data length', dataRow.length, 'should be', dataColumnsCount);
          console.log(dataRow);
        }
        continue;
      }
      const kText = dataRow[0].trim();
      if (kText.length < 1) {
        console.log('CSV parse: unexpected Kernel:');
        console.log(dataRow);
        continue;
      }
      // create basic attributes
      const kItem: SentenceSimilarityItemType = {
        k: kText,
        // k: dataRow[0],
      };
      // import other attributes
      for (let cIdx = 1; cIdx < dataColumnsCount; ++cIdx) {
        const colName = dataHeader[cIdx];
        kItem[colName] = dataRow[cIdx];
      }
      newTransform0Kernel.push(kItem);
    }
    setTransform0Config(prevState => {
      const newState = {...prevState};
      newState.kernel = newTransform0Kernel;
      return newState;
    })
  }

  const loadCSVAsTransform0KernelData = async (files: File[]) => {
    if (!files || files.length !== 1)
      return alert('Please load a single CSV file');
    const fileContent = await files[0].text();
    await loadTransform0KernelData(fileContent);
  }

  const loadSoundFilesAsSourceData = async (files: File[]) => {
    let extraJSON = null;
    try {
      extraJSON = JSON.parse(transcriptionJSON);
      console.log(extraJSON);
    } catch (e) {
      if (transcriptionJSON)
        console.log('Audio transcription: cannot parse extra JSON - ignoring');
    }
    for (let file of files) {
      const fileData: ArrayBuffer = await file.arrayBuffer();
      const fileMD5 = md5(fileData);
      rtttApiClient.sendConversationTranscriptionForAudioFile(file.name, file.size, file.type, fileData, fileMD5, extraJSON);
    }
  }

  const loadAsSourceData = async (filesContent: string[]) => {
    const sourceData: SourceDataType = {
      type: ExampleData.Source.type,
      data: [],
    }
    // read all files
    const singleFile = filesContent.length === 1;
    for (let fileIdx = 0; fileIdx < filesContent.length; fileIdx++) {
      // read all lines of a file
      const fileContent = filesContent[fileIdx];
      const rawLines = fileContent.split('\n');
      for (let lineIdx = 0; lineIdx < rawLines.length; lineIdx++) {
        // cleanup lines, to keep non-empty
        let line = rawLines[lineIdx];
        line = line.trim();
        if (line.length < 1) continue;
        const sourceDataItem: TextConversationItemType = {
          d: line,
          ln: lineIdx + 1,
        };
        if (!singleFile)
          sourceDataItem.fn = fileIdx + 1;
        sourceData.data.push(sourceDataItem);
      }
    }
    // if nothing was read, use default data
    if (sourceData.data === [])
      sourceData.data = ExampleData.Source.data;
    setSourceData(sourceData);
  }

  const loadTextFilesAsSourceData = async (files: File[]) => {
    const filesContent: string[] = await Promise.all(files.map(file => file.text()));
    await loadAsSourceData(filesContent);
  }

  const changeTransform0ModelName = (e) => {
    const userInput = e.target.value;
    const modelName = userInput.replace('.zip', '').trim();
    if (userInput !== modelName)
      e.target.value = modelName;
    setTransform0Config((prevState) => {
      const newState = {...prevState};
      newState.transformer = {...newState.transformer};
      newState.transformer.model = modelName || ExampleData.Transform0.transformer.model;
      return newState;
    });
  }

  return <main>
    {/* CONSOLE */}
    <Box paddingTop={2}>
      <Typography variant="h5" gutterBottom>
        Console
      </Typography>

      <Box display="flex" alignItems="center" my={1}>
        <Typography style={{marginRight: '8px'}}>
          Expected output shape: [{sourceData.data.length}, {transform0Config.kernel.length}]&nbsp;=&nbsp;
          {sourceData.data.length * transform0Config.kernel.length} elements of type '{transform0Config.transformer.output_type}'.
        </Typography>
        <Typography style={{marginRight: '8px'}}>
          Demo configurations:
        </Typography>
        <TextField placeholder="demo key" onChange={e => e.target.value && setDemoKey(e.target.value.toLowerCase())}/>
        <Button size="small" onClick={loadDemoData} disabled={!demoKey || demoKey.length === 0}>Load</Button>
      </Box>

      <Box display="flex" alignItems="center" my={1}>
        <Button color="primary" onClick={performTransform}>Transform&nbsp;<SendIcon/></Button>
      </Box>

      {transformPending && <Box textAlign="center"><CircularProgress color="secondary"/></Box>}

      {res_out && <>
        <SyntaxHighlighter language="json" style={androidstudio}>
          {JSON.stringify(res_out, null, 2)}
        </SyntaxHighlighter>
        TBA: Download CSV || Render Table
      </>}
    </Box>

    {/* TRANSFORM EDIT */}
    <Box mt={4}>
      <Typography variant="h6">
        Transform0 Config
      </Typography>
      <Typography>
        Define the transformations to be applied to the data to output an array of numbers.
      </Typography>

      <Box my={2}>
        <Typography variant="body2">
          <b>Model name</b><br/>
          Configure the model name for the first transform. Other transform architectures/metrics are not supported for now.
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box flexGrow={1}>
            <TextField label="Model name (blank for default)" placeholder={ExampleData.Transform0.transformer.model}
                       onChange={changeTransform0ModelName} fullWidth={true}/>
          </Box>
          <Link href="https://public.ukp.informatik.tu-darmstadt.de/reimers/sentence-transformers/v0.2/" target="_blank">
            Available Models
          </Link>
        </Box>
      </Box>

      <Box my={2}>
        <Typography variant="body2">
          <b>Kernel data</b><br/>
          Imagine the kernel as 1D filter bank of M elements. Running this transform on the input will add M columns of output.
        </Typography>
        <Dropzone onDrop={loadCSVAsTransform0KernelData} accept='text/csv'>
          {({getRootProps, getInputProps}) => <Box {...getRootProps()} bgcolor="#e1ff1e">
            <input {...getInputProps()} />
            <Typography variant="body2">
              <Button size="medium" variant="outlined">Load CSV File (with Header)</Button> or drop a file here.<br/>
              The first column will be the kernel data, other column the attributes to preserve.
            </Typography>
          </Box>}
        </Dropzone>
      </Box>

      <SyntaxHighlighter language="javascript" style={androidstudio}>
        {JSON.stringify(transform0Config, null, 2)}
      </SyntaxHighlighter>
    </Box>

    {/* SOURCE EDIT */}
    <Box mt={4}>
      <Typography variant="h6">
        Source Conversation
      </Typography>
      <Typography>
        Conversation data. Additional fields that are specified for each row will be preserved. Such fields
        may include the speaker name, timestamp, entities, and other metadata.
      </Typography>

      <Box border={1} p={1} bgcolor="#e1ff1e">
        <Dropzone onDrop={loadSoundFilesAsSourceData} accept='audio/mpeg, audio/wav, audio/aac, audio/mp4, audio/m4a'>
          {({getRootProps, getInputProps}) => <Box {...getRootProps()}>
            <input {...getInputProps()} />
            <Typography variant="body2">
              <Button size="small" variant="outlined">Load Audio File</Button> or drop a MP3/WAV file here.
              We will auto-transcribe the file.
            </Typography>
          </Box>}
        </Dropzone>
        <TextField label="Additional JSON" placeholder="{}"
                   onChange={e => setTranscriptionJSON(e.target.value || '')} fullWidth={true}/>
        {transcriptionResult && <SyntaxHighlighter language="javascript" style={androidstudio}>
          {JSON.stringify(transcriptionResult, null, 2)}
        </SyntaxHighlighter>}
      </Box>

      <Box border={1} p={1} bgcolor="#e1ff1e">
        <Dropzone onDrop={loadTextFilesAsSourceData} accept='text/plain'>
          {({getRootProps, getInputProps}) => <Box {...getRootProps()}>
            <input {...getInputProps()} />
            <Typography variant="body2">
              <Button size="small" variant="outlined">Load '\n' Text File</Button> or drop a file here.
              We will use non-empty text lines.
            </Typography>
          </Box>}
        </Dropzone>
      </Box>

      <SyntaxHighlighter language="javascript" style={androidstudio}>
        {JSON.stringify(sourceData, null, 2)}
      </SyntaxHighlighter>
    </Box>

  </main>;
}
