/// TYPES DEF ///

export interface SourceDataType {
  type: string, // 'conversation_time_series'
  data: ConversationItemType[],
}

export interface ConversationItemType {
  d: string,  // data
  s?: string,  // speaker
  ts?: number, // timestamp
}


export interface TransformType {
  input_type: string,
  transformer: {
    family: string,
    model: string,
    metric: string,       // 'cosine_similarity'
    kernel_type: string,  // 'sentence_similarity_array'
    output_type: string,  // 'number'
  }
  kernel: SentenceSimilarityItemType[] | MySentenceSimType[],
}

export interface SentenceSimilarityItemType {
  k: string,
}

export interface MySentenceSimType extends SentenceSimilarityItemType {
  // k is inherited - example of extension
  cat: number,
}


/// HARDCODED DATA EXAMPLES ///

const ExampleSourceData: SourceDataType = {
  type: 'conversation_time_series',
  data: [
    {ts: 0, s: 's1', d: 'I am groot'},
    {ts: 30, s: 's2', d: 'Groot is me'},
    {ts: 31, s: 's1', d: 'Groot?'},
  ]
};

const ExampleTransformConfig: TransformType = {
  input_type: 'conversation_time_series',
  transformer: {
    family: 'sentence-transformers',
    model: 'xlm-r-bert-base-nli-stsb-mean-tokens', // roberta-large-nli-stsb-mean-tokens
    metric: 'cosine_similarity',
    kernel_type: 'sentence_similarity_array',
    output_type: 'number',
  },
  kernel: [
    {k: 'I love strategy', cat: 2},
    {k: 'I love finance', cat: 3},
  ]
}

export const ExampleData = {
  Source: ExampleSourceData,
  Transform0: ExampleTransformConfig,
}
