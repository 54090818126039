import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import {Application} from './Application';
import {TimerViewLayout} from "./layouts/TimerViewLayout";

// A custom theme for this app
import {red} from '@material-ui/core/colors';
import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00a935', // '#5c913b', light: '#a6d388'
      // main: '#004d40', //004d40, 39796b, 00251a
      // main: '#76ff03', //76ff03, b0ff57, 32cb00
    },
    secondary: {
      main: '#d99900', //c1694f
      // dark: '#8a4b38', //8a4b38, 623120
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#eee',
    },
  },
  overrides: {
    MuiTableCell: {   // for more dense cells
      root: {
        padding: '2px 4px',
      },
    },
  },
});


// noinspection JSCheckFunctionSignatures
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Router>
        <Switch>
          {/* Timer Viewer - bypasses the full application */}
          <Route path="/julia/view">
            <TimerViewLayout/>
          </Route>

          {/* Standard root */}
          <Route>
            <Application/>
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
