import React from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PauseIcon from '@material-ui/icons/Pause';
import clsx from "clsx";
import {
  EXPIRED_EMOJIS, TimerBar, TimerAgendaType, TimerScene, TimerStatusType,
  TimerTheme, TimerTickType, TimerZeroBehavior, rtttApiClient,
} from "../logic/RtttApiClient";
import './TimerViewLayout.css';


const useStyles = makeStyles(() => ({
  expander: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },

  centerContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },

  agendaItem: {
    minWidth: '40em',
    paddingBottom: 4,
    paddingLeft: 32,
    paddingTop: 4,
  },
  agendaItemActive: {
    backgroundColor: 'rgba(128,128,128,0.1)',
    minWidth: '40em',
    paddingBottom: 8,
    paddingLeft: 32,
    paddingTop: 8,
  },
  agendaText: {
    fontWeight: 200,
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  agendaTime: {
    fontWeight: 200,
    whiteSpace: 'nowrap',
  },

  barFrame: {
    position: "absolute",
    backgroundColor: 'rgba(128,128,128,0.1)',
  },
  barFrameH: {
    left: 0, bottom: 0, right: 0,
    minHeight: '2em',
  },
  barFrameV: {
    top: 0, left: 0, bottom: 0,
    minWidth: '2em',
    display: 'inline-flex',
  },

  barH: {
    height: '2em',
    marginTop: 0,
  },
  barV: {
    position: "relative",
    width: '2em',
    marginRight: 0,
  },

  // Themes

  logoLight: {
    backgroundColor: 'white',
    color: 'rgba(0,0,0,0.7)',
  },
  logoDark: {
    backgroundColor: '#222',
    color: 'rgba(255,255,255,0.6)',
  },

  timerLight: {
    backgroundColor: 'white', // #83c67f
    color: '#555',
  },
  timerDark: {
    backgroundColor: '#222',
    color: '#CCC',
  },
  timerWarn: {
    color: '#222',
    backgroundColor: '#efa524',
  },
  timerAlert: {
    color: 'white',
    backgroundColor: '#e9422c',
  },
  timerExpired: {
    color: 'white',
    backgroundColor: '#ac0a0a',
  },

  blankLight: {
    backgroundColor: '#EEE',
  },
  blankDark: {
    backgroundColor: '#333',
  },

  barDark: {
    backgroundColor: '#BBB'
  },
  barLight: {
    backgroundColor: '#555'
  },
  barOvertime: {
    opacity: 0.9,
  },
}));


const formatTimer = (s: number, zeroBehavior: TimerZeroBehavior, emojiIndex: number): string => {
  const isTimeOver = s <= 0;
  let deco = '';
  if (isTimeOver) {
    switch (zeroBehavior) {
      case TimerZeroBehavior.Down_Plus:
      case TimerZeroBehavior.Down_Minus:
        if (s < 0)
          deco = (zeroBehavior == TimerZeroBehavior.Down_Plus) ? '+ ' : '- ';
        s = -s;
        break;
      case TimerZeroBehavior.Zero:
        return '0';
      case TimerZeroBehavior.QNA:
        return 'Q & A';
      case TimerZeroBehavior.Emoji:
        return EXPIRED_EMOJIS[emojiIndex];
    }
  }
  const hh = Math.floor(s / 3600);
  const mm = Math.floor((s - 3600 * hh) / 60);
  const ss = Math.floor(s - 3600 * hh - 60 * mm);
  const pad = (n: number) => n.toString().padStart(2, '0');
  return `${deco}${hh ? pad(hh) + ' : ' : ''}${mm ? pad(mm) + ' : ' : ''}${pad(ss)}`;
}

const formatTimerAgenda = (duration: number) => {
  const mm = Math.floor(duration / 60);
  const ss = Math.floor(duration - 60 * mm);
  return <span>
    {mm > 0 ? mm : ''} {mm > 0 && <span style={{opacity: 0.6}}>min</span>}&nbsp;
    {ss > 0 ? ss : ''} {ss > 0 && <span style={{opacity: 0.6}}>s</span>}
  </span>;
}

function ProgressBars({classes, duration, countdown, maxBars, darkTheme, invert, vertical}) {
  if (duration <= 0)
    return null;

  // compute the chunk of bars
  const barLengths = [];
  let progress = (duration - countdown) / duration;
  while (progress >= 0 && barLengths.length < maxBars) {
    const unitProgress = progress > 1 ? 1 : progress;
    const size = Math.round(100 * 100 * unitProgress) / 100;
    barLengths.push(invert ? 100 - size : size);
    progress--;
  }

  // if horizontal, invert the bars (-y) to have the newest on top
  if (!vertical)
    barLengths.reverse();

  // create the bars (more than 1 for how many time you lap the circuit)
  const sizeProp = vertical ? 'height' : 'width';
  const marginProp = vertical ? 'marginTop' : (invert ? 'marginLeft' : 'marginRight');
  return <>
    {barLengths.map((length, idx) => {
      // if horizontal (reverted array), reverse the indices - so the bottom bar is 0
      if (!vertical)
        idx = barLengths.length - idx - 1
      let className = clsx(
        vertical ? classes.barV : classes.barH,
        darkTheme ? classes.barDark : classes.barLight,
        idx > 0 ? classes.barOvertime : null
      );
      return <Box key={`bar-${idx}`} id="timer-progress" display={vertical ? 'block' : 'block'}
                  style={{
                    [sizeProp]: `${length}%`,
                    [marginProp]: 'auto',
                  }} className={className}/>;
    })}
  </>;
}


/** Timer View layout **/

export function TimerViewLayout() {
  const classes = useStyles();

  // server-retrieved state
  const [status, setStatus] = React.useState<TimerStatusType>(undefined);
  const [agenda, setAgenda] = React.useState<TimerAgendaType>(undefined);
  const [tick, setTick] = React.useState<TimerTickType>(undefined);

  React.useEffect(() => {
    const sCallback = status => setStatus(status);
    const aCallback = agenda => setAgenda(agenda);
    const tCallback = tick => setTick(tick);
    rtttApiClient.subTimerStatus(sCallback);
    rtttApiClient.subTimerAgenda(aCallback);
    rtttApiClient.subTimerTick(tCallback);
    return () => {
      rtttApiClient.unsubTimerStatus(sCallback);
      rtttApiClient.unsubTimerAgenda(aCallback);
      rtttApiClient.unsubTimerTick(tCallback);
    };
  }, []);

  if (!status || !tick) return null;

  const s = tick.t;
  const isStopped = status.started === false;
  const isPaused = status.paused;
  const isTimeWarn = s <= status.t_warning;
  const isTimeAlert = s <= status.t_alert;
  const isTimeOver = s <= 0;
  const isDarkTheme = status.theme === TimerTheme.Dark;
  const showProgress = (status.progressBar > TimerBar.Off) && (status.scene == TimerScene.Timer) && !isStopped && !isPaused;
  const progressInverted = status.progressBar == TimerBar.Progress_H_Dec || status.progressBar == TimerBar.Progress_V_Dec;
  const progressVertical = status.progressBar == TimerBar.Progress_V_Dec || status.progressBar == TimerBar.Progress_V_Inc;

  let css = null;
  let content = null;
  switch (status.scene) {
    case TimerScene.Blank:
      css = isDarkTheme ? classes.blankDark : classes.blankLight;
      break;

    case TimerScene.Logo:
      css = isDarkTheme ? classes.logoDark : classes.logoLight;
      content =
        <span>
          <img src="/timer-logo.svg" alt="logo" width="100%"/>
          <Typography variant="h2" noWrap>{status.logoText}</Typography>
        </span>;
      break;

    case TimerScene.Agenda:
      css = isDarkTheme ? classes.logoDark : classes.logoLight;
      const hasAgenda = agenda && agenda.items && agenda.items.length > 0;
      content = <>
        <Typography variant={hasAgenda ? 'h2' : 'h1'} noWrap gutterBottom>
          Agenda
        </Typography>
        {!hasAgenda && <Box mt={8}><Typography variant="h2" className={classes.agendaText}>Not planned yet</Typography></Box>}
        {hasAgenda && agenda.items.map((item, idx) =>
          <Grid key={'agenda-item-' + idx} container className={idx == agenda.activeIdx ? classes.agendaItemActive : classes.agendaItem}>
            {!item.label && <Grid item xs={12}>
              <Box style={{height: '1px', borderTop: '1px solid', margin: 0, opacity: 0.3, marginRight: 36}}/>
            </Grid>}
            {item.label && <Grid item xs={9}>
              <Typography variant="h4" className={classes.agendaText}>
                {item.label}
              </Typography>
            </Grid>}
            {item.label && <Grid item xs={3}>
              <Typography variant="h4" className={classes.agendaTime}>
                {formatTimerAgenda(item.duration)}
              </Typography>
            </Grid>}
          </Grid>
        )}
      </>;
      break;

    case TimerScene.Timer:
      css = isDarkTheme ? classes.timerDark : classes.timerLight;
      if (isStopped) {
      } else if (isTimeOver) {
        if (status.zeroBehavior != TimerZeroBehavior.Emoji && status.zeroBehavior != TimerZeroBehavior.QNA)
          css = classes.timerExpired;
      } else if (isTimeAlert) css = classes.timerAlert;
      else if (isTimeWarn) css = classes.timerWarn;

      if (isStopped)
        content =
          <Typography variant="h1" noWrap>About to begin</Typography>;
      else {
        let contentText = null;
        if (isPaused)
          contentText = <PauseIcon style={{fontSize: '1.2em', marginTop: '0.2em'}}/>;
        else
          contentText = <>{formatTimer(s, status.zeroBehavior, status.zeroEmojiIndex)}</>;
        content = <>
          <Typography variant="h1">
            <span style={{fontSize: '2.5em', whiteSpace: 'nowrap',}}>{contentText}</span>
          </Typography>
          {(status.timerText && !isPaused && !isStopped && !(isTimeOver && status.zeroBehavior == TimerZeroBehavior.Emoji)) &&
          <Typography variant="h1" style={{marginBottom: '-0.8em',}}>
            <span style={{fontSize: '1.4em', whiteSpace: 'nowrap', opacity: 0.6,}}>{status.timerText}</span>
          </Typography>}
        </>
      }
      break;
  }

  return (
    <main id="timer-container" className={clsx(classes.expander, css)}>
      <Box className={classes.centerContent}>
        {content}
      </Box>
      {showProgress && <Box className={clsx(classes.barFrame, progressVertical ? classes.barFrameV : classes.barFrameH)}>
        <ProgressBars classes={classes} duration={status.t_duration} countdown={s} maxBars={1}
                      darkTheme={isDarkTheme} invert={progressInverted} vertical={progressVertical}/>
      </Box>}
    </main>
  );
}
