import React from "react";
import Typography from "@material-ui/core/Typography";
import {Box, Card, CardContent, FormControlLabel, Grid, IconButton, Paper, Switch, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SendIcon from '@material-ui/icons/Send';
import MicIcon from '@material-ui/icons/Mic';
import MicNoneIcon from '@material-ui/icons/MicNone';
import MicOffIcon from '@material-ui/icons/MicOff';

import {AheadResultType, rtttApiClient} from "../logic/RtttApiClient";
import clsx from "clsx";
import {WebSpeechStatusType, WebSpeechTranscriptType, webSpeechUtils} from "../logic/WebSpeechUtils";
import {err, log} from "../logic/utils";

const arrayStat = require('arraystat');
const convertNumbersToWords = require('number-to-words');

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: theme.spacing(8),
  },
  inputs: {
    padding: theme.spacing(2),
  },
  buttons: {
    marginRight: theme.spacing(1),
  },
  micBox: {
    margin: '3em 0',
  },
  micButton: {
    width: '6em',
    height: '6em',
    boxShadow: '0px 2px 11px 3px #dadada',
  },
  micIcon: {
    fontSize: '5em',
  },
  micIconActive: {
    color: theme.palette.primary.main,
  },
  micIconOff: {
    color: 'lightgray',
  },
  dollars: {
    color: 'lightgray',
    fontWeight: 100,
  },
  speechError: {
    color: 'darkred',
    marginTop: '1em',
  },
  aheadError: {
    color: 'darkred',
  },
}));

let trip_start = 0;
let trips = [];
let trips_stats = undefined;

enum MicState {
  MicUnsupported,
  MicOff,
  MicOnStartup,
  MicOnInactive,
  MicOnActive,
}

interface UIStockItem {
  entity?: string,
  symbol?: string,
  price?: number,
  change?: number,
}

let responseCounter = 0;
const uiStockItemsCache: UIStockItem[] = [];
const findStockItem = (uiItems: UIStockItem[], symbol?: string, entity?: string) =>
  uiItems.find(i => (symbol && i.symbol === symbol) || (entity && entity === i.entity))


export function AheadLayout() {
  const classes = useStyles();

  const [speechState, setSpeechState] = React.useState<WebSpeechStatusType>(webSpeechUtils.statusX);
  const [speechError, setSpeechError] = React.useState<string>(undefined);
  const [speechTranscript, setSpeechTranscript] = React.useState<WebSpeechTranscriptType>(undefined);

  const [stockItems, setStockItems] = React.useState<UIStockItem[]>([]);
  const [stockErrors, setStockErrors] = React.useState<string[]>(undefined);
  const [serverResponse, setServerResponse] = React.useState<object>(undefined);

  const [symbolText, setSymbolText] = React.useState<string>('');
  const [showN2W, setShowN2W] = React.useState<boolean>(false);
  const [showErrors, setShowErrors] = React.useState<boolean>(false);

  // SPEECH recognition system

  React.useEffect(() => {
    const onNewTranscript = (transcript: WebSpeechTranscriptType) => {
      // only proceed with UI and resolution for Interim
      if (!transcript.interim) return setSpeechTranscript(transcript);

      const bannedEntities = ['Michael', 'Michaels', 'Mike', 'Elite', 'Maps', 'Marcus', 'Zoo'];
      const entities = transcript.interim.split(' ').map(s => s.trim()).filter(word => {
        // filter bad entities (short, $..., numbers.., not starting with an upper case)
        if (word.length < 3) return false;
        if (word[0] === '$') return false;
        if (word.match(/^\d/)) return false;
        if (bannedEntities.includes(word)) return false;
        return word[0] === word[0].toUpperCase();
      })

      const newUiItemsList: UIStockItem[] = []
      entities.forEach(entity => {
        // local mapping, for speed
        const localEntityToSymbols = {'Qualcomm': 'QCOM'};
        let symbol = localEntityToSymbols[entity];

        // if missing from cache, keep a placeholder and resolve
        let item = findStockItem(uiStockItemsCache, symbol, entity);
        if (!item) {
          // create placeholder item
          item = {
            entity: entity,
            symbol: symbol,
          }
          uiStockItemsCache.push(item);

          // resolve entity/symbol
          trip_start = Date.now(); // TODO: FIXME: per-round-trip, this resets it
          rtttApiClient.sendAheadGetPrice(symbol, !symbol ? entity : undefined);
        }
        newUiItemsList.push(item);
      });

      // schedule UI items update (recycle old items if already in)
      if (newUiItemsList.length > 0)
        setStockItems((prevItems) => {
          const newItems = [];
          newUiItemsList.forEach(item => {
            const existing = findStockItem(prevItems, item.symbol, item.entity);
            newItems.push(existing ? existing : item);
          });
          return newItems;
        });

      // UI update
      setSpeechTranscript(transcript);

      // debug
      const finalizedCount = transcript.final.length;
      console.log(`${finalizedCount}: ${entities} - ${transcript.interim}`);
    }

    // sub/unsub from the callbacks of WebSpeechUtils
    webSpeechUtils.callbacks = {
      status: (status: WebSpeechStatusType) => setSpeechState(Object.assign({}, status)),
      error: (message: string) => setSpeechError(message),
      transcript: onNewTranscript,
    }
    return () => webSpeechUtils.callbacks = null;
  }, [])


  // AHEAD resolution system

  React.useEffect(() => {
    const onAheadResults = (message: AheadResultType) => {
      // handle errors: queue them up
      if ('error' in message)
        setStockErrors(prevErrors => (prevErrors || []).concat(message['error']));

      // symbol resolved
      else if ('symbol' in message) {
        // get the cached placeholder
        const {symbol, entity, quote} = message;
        const newItems = [];
        let item = findStockItem(uiStockItemsCache, symbol, entity);
        if (!item) {
          log(`cache placeholder missing for ${symbol}. adding one.`);
          item = {};
          uiStockItemsCache.push(item);
          newItems.push(item);
        }

        // update item (could be both in cache and on the screen list) with real values
        item.entity = entity;
        item.symbol = symbol;
        item.price = quote['c'];
        item.change = Math.round(100 * 100 * (quote['c'] - quote['pc']) / quote['pc']) / 100;

        // refresh the items
        setStockItems(prevItems => prevItems.concat(newItems));
      }

      // missing handler
      else
        err(`issue with ahead message: ${message}`);

      // increase sequence number and save
      message['seq'] = ++responseCounter;
      setServerResponse(message);
    }

    // sub/unsub from the ahead message
    rtttApiClient.subAheadResult(onAheadResults);
    return () => rtttApiClient.unsubAheadResult(onAheadResults);
  }, []);

  const aheadSubmitSymbolText = () => {
    trip_start = Date.now();
    rtttApiClient.sendAheadGetPrice(symbolText);
  }

  // map speech state to the graphical mic state
  const micState = speechState.supported ? (
    speechState.started ? (
      speechState.recognizing ? (
        speechState.speechOn ? MicState.MicOnActive : MicState.MicOnInactive
      ) : MicState.MicOnStartup
    ) : (
      speechState.recognizing ? MicState.MicOnStartup : MicState.MicOff
    )
  ) : MicState.MicUnsupported;

  const micButtonClicked = () => {
    if (!speechState.supported)
      return;
    if (!speechState.started)
      webSpeechUtils.start();
    else
      webSpeechUtils.stop();
  }

  // misc functions
  const numbers2words = (n: number) => convertNumbersToWords.toWords(Math.round(n));

  return <main>
    {/* Exp UI 1 */}
    <Box paddingTop={2}>
      <Typography align={"center"}>
        Context Flow: <Typography color="primary" display="inline" component="span"><b>financial</b></Typography>
        &nbsp;(locked), <u>Explore topics...</u>
      </Typography>
      <Typography align={"center"} color="textSecondary" gutterBottom>
        Audience: <i>undefined</i>, Other context: <i>undefined</i>
      </Typography>

      <Box textAlign={"center"} className={classes.micBox}>
        <IconButton className={classes.micButton} onClick={() => micButtonClicked()}>
          {micState === MicState.MicUnsupported && <div className={classes.micIcon}>Unsupported</div>}
          {micState === MicState.MicOff && <MicOffIcon className={clsx(classes.micIcon, classes.micIconOff)}/>}
          {micState === MicState.MicOnStartup && <MicNoneIcon className={classes.micIcon}/>}
          {micState === MicState.MicOnActive && <MicIcon className={clsx(classes.micIcon, classes.micIconActive)}/>}
          {micState === MicState.MicOnInactive && <MicIcon className={classes.micIcon}/>}
        </IconButton>
        {speechError && <Typography variant={"h6"} className={classes.speechError}>{speechError}</Typography>}
      </Box>

      <Grid container justify="center" spacing={2} style={{marginBottom: '1em'}}>
        {stockItems.map(item =>
          <Grid item xs={12} sm={6} md={4} key={'sp-' + item.symbol + '-' + item.entity}>
            <Card elevation={2}>
              <CardContent>
                {item.symbol && <Typography color="primary">{item.symbol} &nbsp;</Typography>}
                {item.price &&
                <Typography variant="body1"><b>{showN2W ? numbers2words(item.price) : Math.round(item.price * 100) / 100}</b>&nbsp;
                  <span className={classes.dollars}>dollars</span></Typography>}
                {item.change && <Typography color="textSecondary" variant="body2">change:&nbsp;
                  {item.change}%&nbsp;
                  {item.change > 0 ? <ArrowUpwardIcon fontSize="small" style={{color: 'green', marginBottom: '-3px'}}/> :
                    item.change < 0 ? <ArrowDownwardIcon fontSize="small" style={{color: 'red', marginBottom: '-4px'}}/> : ''}
                </Typography>}
                {item.entity && <Typography color="secondary">{item.entity}</Typography>}
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      {speechTranscript && <Box textAlign="center">
        {speechTranscript.interim &&
        <Typography align="center">
          {speechTranscript.interim}...
        </Typography>
        }
        {speechTranscript.final.slice(0).reverse().slice(0, 5).map((segment, idx) =>
          <Typography variant="body2" color="textSecondary" align="center" key={'st-' + idx}>
            {segment}
          </Typography>
        )}
      </Box>}

      <Typography align={"center"} style={{marginTop: '1em'}}>
        This experiment tests the end-to-end latency and cognitive self-load for speech completion
        with information retrieval, mediated by the human brain (speak, see, read, continue speaking).
      </Typography>
    </Box>


    {/* Manual UIs */}
    <Typography variant="h6" className={classes.section} gutterBottom>
      Manual operations
    </Typography>
    <Paper className={classes.inputs}>
      <Box style={{display: 'flex'}}>
        <TextField label="Financial context - enter symbol" placeholder="TSLA" onChange={e => setSymbolText(e.target.value)}
                   style={{flexGrow: 1}}
                   onKeyPress={e => {
                     if (e.key === 'Enter') {
                       e.preventDefault();
                       aheadSubmitSymbolText();
                     }
                   }}/>
        <IconButton color="primary" className={classes.buttons} disabled={!symbolText || symbolText.length < 2}
                    onClick={() => aheadSubmitSymbolText()}>
          Add&nbsp;<SendIcon/>
        </IconButton>
      </Box>

      <Box>
        <FormControlLabel label="Numbers to words" control={
          <Switch checked={showN2W} onChange={e => setShowN2W(e.target.checked)} color="primary"/>
        }/>
      </Box>

      <Box>
        <FormControlLabel label="Debugging" control={
          <Switch checked={showErrors} onChange={e => setShowErrors(e.target.checked)} color="primary"/>
        }/>
      </Box>

      {showErrors && serverResponse && <Box>
        <Typography>Server response:</Typography>
        <pre>{JSON.stringify(serverResponse, null, 2)}</pre>
        {(() => {
          if (trip_start > 0) {
            const trip_pre_render = Date.now() - trip_start;
            trips.push(trip_pre_render);
            trip_start = 0;
            trips_stats = arrayStat(trips);
          }
          return <div>{trips[trips.length - 1]} ms - avg: {Math.round(trips_stats.avg)},
            stdev: {Math.round(trips_stats.stddev * 100)}%</div>
        })()}
      </Box>}

      {showErrors && stockErrors && stockErrors.length &&
      <Box style={{marginTop: '1em'}}>
        {stockErrors.slice().reverse().map((error, idx) =>
          <Typography className={classes.aheadError} key={'err-' + idx}>{error}</Typography>)}
      </Box>}
    </Paper>

  </main>;
}
